import jwtDecode from "jwt-decode";

//========== API 관련
// 토큰 정보 확인
const tokenInfo = () => {
  const token = sessionStorage.getItem("token");
  const tokenid = token && jwtDecode(token)?.id;
  const tokenuser = token && jwtDecode(token)?.userid;
  const tokenrole = token && jwtDecode(token)?.role;
  const tokenhsp = token && jwtDecode(token)?.hspname;

  return { token, tokenid, tokenuser, tokenrole, tokenhsp };
};

// 날짜 포맷 변환 (0000.00.00)
const formatDotYmd = (dateInfo) => {
  const date = dateInfo.slice(0, 10);
  const yyyy = date.split("-")[0].toString();
  const mm = date.split("-")[1].toString();
  const dd = date.split("-")[2].toString();
  return (
    yyyy + "." + (mm[1] ? mm : "0" + mm[0]) + "." + (dd[1] ? dd : "0" + dd[0])
  );
};

// 전화번호 자동 하이픈 (000-0000-0000)
const autoHypenPhone = (str) => {
  str = str
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return str;
};

// 이미지 노출되는 이름 변경
const shortImgName = (imgPath) => {
  return imgPath.slice(imgPath.indexOf("_") + 1, imgPath.length);
};

export { tokenInfo, formatDotYmd, autoHypenPhone, shortImgName };
