import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  FlexBox,
  Text,
  Btn,
  CheckLabel,
  CheckIcon,
} from "../../Assets/css/common";
import {
  ColorMainBlue,
  ColorBgGray,
  ColorDarkBlack,
  ColorGray,
  ColorWhite,
  ColorLightBlack,
} from "../../Utiles/Color";
import { regEmail, regPasswd, regPhone, regSellerCode } from "../../Utiles/Reg";
import ico_person from "../../Assets/images/ico_person.png";
import ico_lock from "../../Assets/images/ico_lock.png";
import ico_building from "../../Assets/images/ico_building.png";
import ico_phone from "../../Assets/images/ico_phone.png";
import ico_mail from "../../Assets/images/ico_mail.png";
import ico_location from "../../Assets/images/ico_location.png";
import ico_document from "../../Assets/images/ico_document.png";
import ico_back from "../../Assets/images/ico_back_btn.png";
import TextInput from "../../Components/Input/TextInput";
import FileInput from "../../Components/Input/FileInput";
import { API } from "../../Utiles/API";
import { autoHypenPhone } from "../../Utiles/common";
import ConfirmModal from "../../Components/ConfirmModal";
import AgreeModal from "../../Components/AgreeModal";

// 회원가입

function Join() {
  const navigate = useNavigate();
  // * 아이디, 비밀번호, 병원명, 담당자명, 전화번호, 사업자등록증
  const joinItems = {
    userid: "",
    passwd: "",
    passwd2: "",
    corpname: "",
    username: "",
    phone: "",
    phone_certify: "",
    email: "",
    address: "",
    corpfile: "",
    agree: "",
    sellercode: "",
  };
  const [values, setValues] = useState(joinItems);
  const {
    userid,
    passwd,
    passwd2,
    corpname,
    username,
    phone,
    phone_certify,
    email,
    address,
    corpfile,
    agree,
    sellercode,
  } = values;
  const [fileValue, setFileValue] = useState("");
  const [msgs, setMsgs] = useState(joinItems);
  const [check, setCheck] = useState(false);
  const [certifyNum, setCertifyNum] = useState(0);
  const [certify, setCertify] = useState(false);
  const [agreeModal, setAgreeModal] = useState(false);
  const [modal, setModal] = useState(false);

  const handleChange = (e) => {
    let val = e.target.value;
    if (e.target.id === "phone") {
      val = autoHypenPhone(val);
    }
    if (e.target.id === "phone_certify") {
      val = val.replace(/[^0-9]/g, "").substring(0, 4);
    }
    setValues({ ...values, [e.target.id]: val });
    msgReset();
  };
  const handleFileChange = (id, val) => {
    setValues({ ...values, [id]: val });
    msgReset();
  };
  const msgReset = () => setMsgs(joinItems);
  const goBack = () => navigate(-1);

  const onJoin = async (e) => {
    e.preventDefault();

    if (!userid)
      return setMsgs({
        ...msgs,
        userid: "아이디를 입력해주세요.",
      });
    if (!passwd)
      return setMsgs({
        ...msgs,
        passwd: "비밀번호를 입력해주세요.",
      });
    if (!regPasswd.test(passwd))
      return setMsgs({
        ...msgs,
        passwd: "비밀번호는 특수문자 포함 7자리 이상 입력해주세요.",
      });
    if (passwd !== passwd2)
      return setMsgs({
        ...msgs,
        passwd2: "비밀번호가 일치하지 않습니다.",
      });
    if (!corpname)
      return setMsgs({
        ...msgs,
        corpname: "병원명을 입력해주세요.",
      });
    if (!username)
      return setMsgs({
        ...msgs,
        username: "담당자명을 입력해주세요.",
      });
    if (!phone)
      return setMsgs({
        ...msgs,
        phone: "전화번호를 입력해주세요.",
      });
    if (!certify)
      return setMsgs({
        ...msgs,
        phone_certify: "본인인증을 해주세요.",
      });
    if (!regPhone.test(phone))
      return setMsgs({
        ...msgs,
        phone: "올바른 전화번호 형식이 아닙니다.",
      });
    if (email && !regEmail.test(email))
      return setMsgs({
        ...msgs,
        email: "올바른 이메일 형식이 아닙니다.",
      });
    // if (!corpfile)
    //   return setMsgs({
    //     ...msgs,
    //     corpfile: "사업자등록증이 확인되어야 계정이 생성됩니다.",
    //   });
    if (sellercode && !regSellerCode.test(sellercode))
      return setMsgs({
        ...msgs,
        sellercode: "올바른 대리점코드가 아닙니다.",
      });
    if (!check)
      return setMsgs({
        ...msgs,
        agree: "개인정보 수집 및 이용에 동의해주세요.",
      });

    getIdCheck();
  };
  const onAgreeCheck = () => {
    setCheck((state) => !state);
    msgReset();
  };
  const modalSubmit = () => {
    postJoin();
  };
  const onPhoneCertify = () => {
    if (certifyNum === Number(phone_certify)) {
      alert("인증되었습니다.");
      setCertify(true);
    } else {
      alert("인증번호가 일치하지 않습니다.");
    }
  };

  //========== API
  const getIdCheck = async () => {
    const res = await API.get(`/idcheck?userid=${userid}`);
    const resData = res.data;
    if (resData === 0) {
      msgReset();
      setModal(true);
    } else {
      alert("사용할 수 없는 아이디입니다.");
    }
  };
  const postJoin = async () => {
    try {
      let data = { ...values };
      delete data.passwd2;
      delete data.phone_certify;
      if (!address) data = { ...data, address: null };
      if (!email) data = { ...data, email: null };
      if (!sellercode) data = { ...data, sellercode: null };
      if (fileValue) data = { ...data, corpfile: fileValue };
      const formData = JSON.stringify(data);
      const res = await API.post("/register", formData);

      if (res === null) return alert("오류 : 회원가입 실패!");
      alert("가입이 완료되었습니다. 로그인해주세요!");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const getPhoneCertify = async () => {
    if (phone) {
      const num = Math.floor(Math.random() * 9000) + 1000;
      setCertifyNum(num);

      try {
        const phonenum = phone.split("-").join("");
        const res = await API.get(
          `/phonecheck?rcvnum=${phonenum}&certnum=${num}`
        );

        if (res === null) return alert("오류 : 인증번호 전송 실패!");
        alert("인증번호를 전송했습니다. 인증번호를 입력해주세요.");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <HeaderSub className="flex md:hidden">
        <BackBtn onClick={goBack}>
          <img src={ico_back} alt="이전 페이지" />
        </BackBtn>
        <Text size={"16px"} color={ColorDarkBlack} weight={"700"}>
          회원가입
        </Text>
      </HeaderSub>
      <Wrapper>
        <JoinWrap>
          <Text
            as="h2"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block"
          >
            회원가입
          </Text>
          <form onSubmit={onJoin}>
            <FlexBox
              direction={"column"}
              gap={"20px"}
              className="mt-0 md:mt-7.5"
            >
              <TextInput
                type={"text"}
                phText={"아이디"}
                icon={ico_person}
                id={"userid"}
                value={values.userid}
                onChange={handleChange}
                validMsg={msgs.userid}
              />
              <TextInput
                type={"password"}
                phText={"비밀번호"}
                icon={ico_lock}
                id={"passwd"}
                value={values.passwd}
                onChange={handleChange}
                validMsg={msgs.passwd}
                autoComplete={"new-password"}
              />
              <TextInput
                type={"password"}
                phText={"비밀번호 확인"}
                icon={ico_lock}
                id={"passwd2"}
                value={values.passwd2}
                onChange={handleChange}
                validMsg={msgs.passwd2}
              />
              <TextInput
                type={"text"}
                phText={"병원명"}
                icon={ico_building}
                id={"corpname"}
                value={values.corpname}
                onChange={handleChange}
                validMsg={msgs.corpname}
              />
              <TextInput
                type={"text"}
                phText={"담당자명"}
                icon={ico_person}
                id={"username"}
                value={values.username}
                onChange={handleChange}
                validMsg={msgs.username}
              />
              <VertifyWrap>
                <TextInput
                  type={"text"}
                  phText={"전화번호"}
                  icon={ico_phone}
                  id={"phone"}
                  value={values.phone}
                  onChange={handleChange}
                  validMsg={msgs.phone}
                  disabled={certify}
                />
                <Btn
                  type="button"
                  size={"14px"}
                  color={ColorWhite}
                  width={"80px"}
                  padding={"14px 0 13px"}
                  radius={"6px"}
                  bgColor={ColorGray}
                  onClick={getPhoneCertify}
                  disabled={certify}
                >
                  본인 인증
                </Btn>
              </VertifyWrap>
              <VertifyWrap>
                <TextInput
                  type={"text"}
                  phText={"인증번호"}
                  icon={ico_phone}
                  id={"phone_certify"}
                  value={values.phone_certify}
                  onChange={handleChange}
                  validMsg={msgs.phone_certify}
                  disabled={certify}
                />
                <Btn
                  type="button"
                  size={"14px"}
                  color={ColorWhite}
                  width={"80px"}
                  padding={"14px 0 13px"}
                  radius={"6px"}
                  bgColor={ColorGray}
                  onClick={onPhoneCertify}
                  disabled={certify}
                >
                  인증 확인
                </Btn>
              </VertifyWrap>
              <TextInput
                type={"text"}
                phText={"이메일"}
                icon={ico_mail}
                id={"email"}
                value={values.email}
                onChange={handleChange}
                validMsg={msgs.email}
              />
              <TextInput
                type={"text"}
                phText={"주소"}
                icon={ico_location}
                id={"address"}
                value={values.address}
                onChange={handleChange}
              />
              <div>
                <FileInput
                  phText={"사업자 등록증"}
                  icon={ico_document}
                  id={"corpfile"}
                  value={values.corpfile}
                  onChange={handleFileChange}
                  setFile={setFileValue}
                  validMsg={msgs.corpfile}
                />
                <p className="valid_msg">
                  * 사업자등록증을 첨부해야 다운로드가 가능합니다.
                </p>
              </div>
              <div>
                <TextInput
                  type={"text"}
                  upper={true}
                  phText={"대리점코드"}
                  icon={ico_document}
                  id={"sellercode"}
                  value={values.sellercode}
                  onChange={handleChange}
                  validMsg={msgs.sellercode}
                />
                <p className="valid_msg">
                  * 대리가입 해주시는 영업소의 코드를 입력해주세요.
                </p>
              </div>
              <FlexBox align={"flex-start"} gap={"10px"}>
                <CheckLabel htmlFor="idSave" className="mt-1">
                  <input
                    type="checkbox"
                    id="idSave"
                    checked={check}
                    onChange={onAgreeCheck}
                  />
                  <CheckIcon>
                    <svg viewBox="0 0 24 24">
                      <polyline points="19 7 10 17 5 12" />
                    </svg>
                  </CheckIcon>
                </CheckLabel>
                <div>
                  <Btn
                    type="button"
                    size={"14px"}
                    color={ColorLightBlack}
                    weight={"600"}
                    onClick={() => {
                      setCheck(true);
                      setAgreeModal(true);
                    }}
                  >
                    개인정보 수집 및 이용 동의{" "}
                    <span
                      className="font-normal"
                      style={{ color: ColorMainBlue }}
                    >
                      [필수]
                    </span>
                  </Btn>
                  {msgs.agree && <p className="valid_msg">{msgs.agree}</p>}
                </div>
              </FlexBox>
            </FlexBox>
            <Btn
              type="submit"
              size={"16px"}
              color={"#fff"}
              weight={"700"}
              width={"100%"}
              padding={"12px 0"}
              radius={"6px"}
              bgColor={ColorMainBlue}
              className="mt-10"
            >
              회원가입
            </Btn>
          </form>
        </JoinWrap>
      </Wrapper>
      {agreeModal && <AgreeModal onSubmit={() => setAgreeModal(false)} />}
      {modal && (
        <ConfirmModal
          text={"회원가입을 완료하시겠습니까?"}
          onSubmit={modalSubmit}
          onCancel={() => setModal(false)}
        />
      )}
    </>
  );
}

const HeaderSub = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 8px;
  background-color: ${ColorBgGray};
  z-index: 5;
`;
const BackBtn = styled.button`
  position: absolute;
  top: calc(50% - 20px);
  left: 8px;
  padding: 4px;
  img {
    width: 32px;
  }
`;
const Wrapper = styled.div`
  min-height: 100vh;
  padding: 160px 0 100px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 76px 0 60px;
  }
`;
const JoinWrap = styled.div`
  width: 100%;
  max-width: 412px;
  padding: 0 16px;
  margin: 0 auto;
`;
const VertifyWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  & > div {
    flex: 1;
  }
`;

export default Join;
