import React from "react";
import Header from "../../Components/Header";
import MainVisual from "./MainVisual";
import MainQuick from "./MainQuick";
import MainService from "./MainService";
import MainAsk from "./MainAsk";
import MainProcess from "./MainProcess";
import MainHr from "./MainHr";
import MainPartner from "./MainPartner";
import Footer from "../../Components/Footer";

// 메인

function Main() {
  return (
    <>
      <Header />
      <MainVisual />
      <MainQuick />
      <MainService />
      <MainAsk />
      <MainProcess />
      <MainHr />
      <MainPartner />
      <Footer />
    </>
  );
}

export default Main;
