import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ColorBgGray,
  ColorBlack,
  ColorDarkBlack,
  ColorLightBlack,
  ColorLightGray,
  ColorMainBlue,
  ColorWhite,
} from "../../Utiles/Color";
import { Container, Text } from "../../Assets/css/common";
import "../../Assets/css/swiper";
import img_service1 from "../../Assets/images/main_service_img01.png";
import img_service2 from "../../Assets/images/main_service_img02.png";
import img_service3 from "../../Assets/images/main_service_img03.png";
import img_service4 from "../../Assets/images/main_service_img04.png";
import img_service5 from "../../Assets/images/main_service_img05.png";

function MainService() {
  const sliderParams = {
    spaceBetween: 5,
    slidesPerView: 2.1,
    breakpoints: {
      768: { spaceBetween: 10, slidesPerView: 3 },
      1024: { spaceBetween: 20, slidesPerView: 5 },
    },
    style: { overflow: "visible" },
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Text
            as="h3"
            color={ColorDarkBlack}
            weight={"700"}
            align={"center"}
            className="mb-4 md:mb-5 text-1.5xl md:text-4xl lg:text-5xl break-keep"
          >
            Canon ERP는 <br className="block md:hidden" />
            <span style={{ color: ColorMainBlue }}>
              빠르고, 편리하고, 안전합니다.
            </span>
          </Text>
          <Text
            color={ColorLightBlack}
            align={"center"}
            className="mb-7.5 text-xs md:text-lg font-normal md:font-semibold break-keep"
          >
            우수한 기술력과 경험을 기반으로 <br className="block md:hidden" />
            고객 맞춤형 서비스를 제공합니다.
          </Text>
          <Swiper {...sliderParams}>
            <SwiperSlide>
              <SlideImg>
                <img src={img_service1} alt="서비스 이미지" />
              </SlideImg>
              <Text
                color={ColorBlack}
                weight={"600"}
                align={"center"}
                className="mt-2 md:mt-2.5 text-xs md:text-sm break-keep"
              >
                간편 바코드 스캔으로 발주/ <br />
                입고/ 출고를 쉽게 정리
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <SlideImg>
                <img src={img_service2} alt="서비스 이미지" />
              </SlideImg>
              <Text
                color={ColorBlack}
                weight={"600"}
                align={"center"}
                className="mt-2 md:mt-2.5 text-xs md:text-sm break-keep"
              >
                발주 시 거래처에 <br />
                카카오톡 알림 발송
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <SlideImg>
                <img src={img_service3} alt="서비스 이미지" />
              </SlideImg>
              <Text
                color={ColorBlack}
                weight={"600"}
                align={"center"}
                className="mt-2 md:mt-2.5 text-xs md:text-sm break-keep"
              >
                고시단가를 실시간으로 검증, <br />
                편리한 거래 업체 변경
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <SlideImg>
                <img src={img_service4} alt="서비스 이미지" />
              </SlideImg>
              <Text
                color={ColorBlack}
                weight={"600"}
                align={"center"}
                className="mt-2 md:mt-2.5 text-xs md:text-sm break-keep"
              >
                클라우드 서비스로 <br />
                안전한 데이터 관리
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <SlideImg>
                <img src={img_service5} alt="서비스 이미지" />
              </SlideImg>
              <Text
                color={ColorBlack}
                weight={"600"}
                align={"center"}
                className="mt-2 md:mt-2.5 text-xs md:text-sm break-keep"
              >
                국제표준(GS-128) 코드를 <br />
                이용한 편리한 업무
              </Text>
            </SwiperSlide>
          </Swiper>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0;
  background-color: ${ColorBgGray};
  overflow: hidden;

  @media all and (max-width: 767px) {
    padding: 30px 0 40px;
  }
`;
const SlideImg = styled.div`
  border: 1px solid ${ColorLightGray};
  padding: 25px;
  background-color: ${ColorWhite};
  img {
    max-width: 100%;
    margin: 0 auto;
  }

  @media all and (max-width: 767px) {
    width: calc(100% - 15px);
    margin: 0 auto;
    padding: 10px 25px;
  }
`;

export default MainService;
