import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Th, Td, Table, Text, Btn } from "../../../Assets/css/common";
import {
  ColorBlack,
  ColorLightBlack,
  ColorLightGray,
  ColorMainBlue,
  ColorWhite,
} from "../../../Utiles/Color";
import ico_arr from "../../../Assets/images/arr02.png";
import { API } from "../../../Utiles/API";
import { formatDotYmd, tokenInfo } from "../../../Utiles/common";
import Pagination from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";

// Cannon ERP > 소식 > 공지사항

function NewsNotice() {
  const tkInfo = tokenInfo();
  const tokenrole = tkInfo.tokenrole;
  // const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const limit = 10;
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);

  //========== API
  const getNoticeList = async () => {
    const res = await API.get("/notice");
    console.log("res",res)
    const resData = res.data.list;
    console.log("resData",resData)
    const filterData = resData?.filter((item) => {
      if (item.gbdc !== "DC") return item;
    });
    // console.log(resData, filterData);
    setTotalData(filterData);
    setData(showListPc(filterData, page, limit));
    setLoading(false);
  };
  const showListPc = (data, page, limit) => {
    const idxLast = page * limit;
    const idxFirst = idxLast - limit;
    const showItems = data?.slice(idxFirst, idxLast);
    return showItems;
  };
  const showListMobile = (data, page, limit) => {
    const idxLast = page * limit;
    const showItems = data?.slice(0, idxLast);
    return showItems;
  };
  const moreBtnClick = () => {
    setPage((state) => (state += 1));
  };

  useEffect(() => {
    getNoticeList();
  }, []);
  useEffect(() => {
    const totalPage = Math.ceil(totalData?.length / limit);
    if (totalPage <= page) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [data]);
  useEffect(() => {
    const winW = window.innerWidth;

    setData();
    if (winW > 767) {
      setData(showListPc(totalData, page, limit));
    } else {
      setData(showListMobile(totalData, page, limit));
    }
  }, [page]);

  return (
    <>
      <Table className="block md:table">
        <colgroup className="hidden md:table-column-group">
          <col style={{ width: "10.5%" }} />
          <col style={{ width: "14%" }} />
          <col />
          <col style={{ width: "17.5%" }} />
        </colgroup>
        <thead className="hidden md:table-header-group">
          <tr>
            <Th size={"16px"} bgColor={ColorLightGray}>
              No
            </Th>
            <Th size={"16px"} bgColor={ColorLightGray}>
              작성일
            </Th>
            <Th size={"16px"} bgColor={ColorLightGray}>
              제목
            </Th>
            <Th size={"16px"} bgColor={ColorLightGray}>
              등록부서
            </Th>
          </tr>
        </thead>
        <tbody className="block md:table-row-group">
          {loading ? (
            <tr className="block md:table-row">
              <TdLoad colSpan="4" className="block md:table-cell">
                <Loading />
              </TdLoad>
            </tr>
          ) : (
            data?.length === 0 && (
              <tr className="block md:table-row">
                <TdNone colSpan="4" className="block md:table-cell">
                  <Text className="text-xs md:text-sm">
                    등록된 공지사항이 없습니다.
                  </Text>
                </TdNone>
              </tr>
            )
          )}
          {data?.map((e) => {
            const date = formatDotYmd(e.createdate);

            return (
              <tr key={e.idx} className="block md:table-row">
                {/* pc */}
                <Td className="hidden md:table-cell">
                  <Text size={"14px"}>{e.idx}</Text>
                </Td>
                <Td className="hidden md:table-cell">
                  <Text size={"14px"}>{date}</Text>
                </Td>
                <Td align={"left"} className="hidden md:table-cell">
                  <Btn
                    style={{ maxWidth: "100%" }}
                    onClick={() => {
                      const pageLink =
                        tokenrole === "ROLE_ADMIN"
                          ? `/erp/news/write/${e.idx}`
                          : `/erp/news/view/${e.idx}`;
                      navigate(pageLink);
                    }}
                  >
                    <Text size={"14px"} color={ColorBlack} className="ellipsis">
                      {e.title}
                    </Text>
                  </Btn>
                </Td>
                <Td className="hidden md:table-cell">
                  <Text size={"14px"}>{e.buse}</Text>
                </Td>
                {/* mobile */}
                <Tdm className="block md:hidden">
                  <Btn
                    onClick={() => {
                      const pageLink =
                        tokenrole === "ROLE_ADMIN"
                          ? `/erp/news/write/${e.idx}`
                          : `/erp/news/view/${e.idx}`;
                      navigate(pageLink);
                    }}
                  >
                    <div>
                      <Text
                        size={"12px"}
                        color={ColorBlack}
                        className="ellipsis mb-1"
                      >
                        {e.title}
                      </Text>
                      <Text size={"12px"} color={ColorLightBlack}>
                        <span style={{ color: ColorMainBlue }}>{e.buse}</span>
                        {e.buse && date ? " | " : ""}
                        {date}
                      </Text>
                    </div>
                    <i>
                      <img src={ico_arr} alt="arrow" />
                    </i>
                  </Btn>
                </Tdm>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TableBottom className="mt-7 hidden md:block">
        <Pagination
          limit={limit}
          total={totalData?.length}
          page={page}
          setPage={setPage}
        ></Pagination>
        {tokenrole === "ROLE_ADMIN" && (
          <Btn
            size={"14px"}
            color={ColorWhite}
            weight={"600"}
            width={"80px"}
            height={"48px"}
            radius={"4px"}
            bgColor={ColorMainBlue}
            onClick={() => navigate("/erp/news/write")}
          >
            등 록
          </Btn>
        )}
      </TableBottom>
      {showMore ? (
        <Btn
          size={"12px"}
          color={ColorMainBlue}
          weight={"600"}
          border={`1px solid ${ColorMainBlue}`}
          radius={"24px"}
          width={"120px"}
          height={"40px"}
          className="block md:hidden mt-7.5 mx-auto"
          onClick={moreBtnClick}
        >
          더보기
        </Btn>
      ) : (
        ""
      )}
      {tokenrole === "ROLE_ADMIN" && (
        <WriteBtn
          className="block md:hidden"
          onClick={() => navigate("/erp/news/write")}
        >
          <i></i>
        </WriteBtn>
      )}
    </>
  );
}

const TdLoad = styled(Td)`
  height: 490px;
  vertical-align: top;

  @media all and (max-width: 767px) {
    height: auto;
    padding: 22px 12px;
  }
`;
const TdNone = styled(Td)`
  @media all and (max-width: 767px) {
    padding: 22px 12px;
  }
`;
const Tdm = styled(Td)`
  padding: 0;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 4px 14px 12px;
    text-align: left;

    i {
      display: block;
    }
  }
`;
const TableBottom = styled.div`
  position: relative;
  padding: 12px 0;

  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
const WriteBtn = styled(Btn)`
  position: fixed;
  right: 16px;
  bottom: 40px;
  padding: 11px;
  border-radius: 50%;
  background-color: ${ColorMainBlue};
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);
  z-index: 100;

  i {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${ColorWhite};
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${ColorWhite};
    }
  }
`;

export default NewsNotice;
