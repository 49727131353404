import React from "react";
import styled from "styled-components";
import ico_arrow from "../../Assets/images/arr_select.png";
import {
  ColorDarkGray,
  ColorGray,
  ColorLightGray,
  ColorWhite,
} from "../../Utiles/Color";

function Select(props) {
  const { options, id, value, onChange } = props;

  return (
    <>
      <SelectStyle id={id} value={value} onChange={onChange}>
        {options?.map((e) => (
          <option key={e.value} value={e.value}>
            {e.name}
          </option>
        ))}
      </SelectStyle>
    </>
  );
}

const SelectStyle = styled.select`
  width: 100%;
  padding: 13px 10px 12px;
  border: 1px solid ${ColorLightGray};
  border-radius: 6px;
  background: ${ColorWhite} url(${ico_arrow}) no-repeat right 14px center / 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${ColorDarkGray};
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;

  &::placeholder {
    color: ${ColorGray};
  }
  &::-ms-expand {
    display: none;
  }
`;

export default Select;
