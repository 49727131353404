import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ColorDarkGray } from "../../Utiles/Color";
import ico_pgprev from "../../Assets/images/arr_page_prev.png";
import ico_pgnext from "../../Assets/images/arr_page_next.png";

function Pagination(props) {
  const { limit, total, page, setPage } = props;
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  const pageLimit = 10; // 페이지번호 한 번에 10개씩 노출
  const totalPage = Math.ceil(total / limit);
  const pageIdx = page % pageLimit;
  const pageGroup = pageIdx !== 0 ? page - pageIdx : page - pageIdx - pageLimit;

  const pageNumbers = [];
  for (let i = 1; i <= pageLimit; i++) {
    const realPage = pageGroup + i;
    if (realPage > totalPage) break;
    pageNumbers.push(realPage);
  }

  const prevPage = () => {
    pageGroup > 0 && setPage(parseInt(pageGroup));
  };
  const nextPage = () => {
    totalPage > pageGroup + pageLimit &&
      setPage(parseInt(pageGroup) + parseInt(pageLimit) + 1);
  };

  useEffect(() => {
    pageGroup > 0 ? setPrevDisabled(false) : setPrevDisabled(true);
    totalPage > pageGroup + pageLimit
      ? setNextDisabled(false)
      : setNextDisabled(true);
  }, [page]);

  return (
    <>
      <PagerWrap>
        <button
          className="page_prev"
          disabled={prevDisabled}
          onClick={prevPage}
        >
          <i></i>
        </button>
        {pageNumbers?.map((num) => (
          <button
            key={num}
            className={page === num ? "active" : ""}
            onClick={() => setPage(num)}
          >
            {num}
          </button>
        ))}
        <button
          className="page_next"
          disabled={nextDisabled}
          onClick={nextPage}
        >
          <i></i>
        </button>
      </PagerWrap>
    </>
  );
}

const PagerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin ?? props.margin};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: ${ColorDarkGray};

    &.active {
      color: #2d2d2d;
    }
    &.page_prev {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgprev}) no-repeat center center / contain;
      }
    }
    &.page_next {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgnext}) no-repeat center center / contain;
      }
    }
  }
`;

export default Pagination;
