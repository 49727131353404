import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import Main from "./Pages/Main";
import Login from "./Pages/Login";
import Join from "./Pages/Join";
import Mypage from "./Pages/Mypage";
import ErpIntro from "./Pages/Erp/Intro";
import ErpNews from "./Pages/Erp/News";
import NoticeView from "./Pages/Erp/News/NoticeView";
import NoticeWrite from "./Pages/Erp/News/NoticeWrite";
import ServicePrice from "./Pages/Service/Price";
import ServiceContact from "./Pages/Service/Contact";
import CustomerInquiry from "./Pages/Customer/Inquiry";
import InquiryView from "./Pages/Customer/Inquiry/InquiryView";
import InquiryWrite from "./Pages/Customer/Inquiry/InquiryWrite";
import CustomerGuide from "./Pages/Customer/Guide";
import VideoView from "./Pages/Customer/Guide/VideoView";
import VideoWrite from "./Pages/Customer/Guide/VideoWrite";
import DownDownload from "./Pages/Download/Download";
import DownUpdate from "./Pages/Download/Update";
import UpdateView from "./Pages/Download/Update/UpdateView";
import UpdateWrite from "./Pages/Download/Update/UpdateWrite";
import ErrorPage from "./Pages/ErrorPage";

function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/join" element={<Join />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/erp/intro" element={<ErpIntro />} />
        <Route path="/erp/news" element={<ErpNews />} />
        <Route path="/erp/news/view/:idx" element={<NoticeView />} />
        <Route path="/erp/news/write" element={<NoticeWrite />} />
        <Route path="/erp/news/write/:idx" element={<NoticeWrite />} />
        <Route path="/service/price" element={<ServicePrice />} />
        <Route path="/service/contact" element={<ServiceContact />} />
        <Route path="/customer/inquiry" element={<CustomerInquiry />} />
        <Route path="/customer/inquiry/view/:idx" element={<InquiryView />} />
        <Route path="/customer/inquiry/write" element={<InquiryWrite />} />
        <Route path="/customer/inquiry/write/:idx" element={<InquiryWrite />} />
        <Route path="/customer/guide" element={<CustomerGuide />} />
        <Route path="/customer/guide/video" element={<CustomerGuide />} />
        <Route path="/customer/guide/docs/:np_id" element={<CustomerGuide />} />
        <Route path="/customer/guide/view/:idx" element={<VideoView />} />
        <Route path="/customer/guide/write" element={<VideoWrite />} />
        <Route path="/customer/guide/write/:idx" element={<VideoWrite />} />
        <Route path="/download/download" element={<DownDownload />} />
        <Route path="/download/update" element={<DownUpdate />} />
        <Route path="/download/update/view/:idx" element={<UpdateView />} />
        <Route path="/download/update/write" element={<UpdateWrite />} />
        <Route path="/download/update/write/:idx" element={<UpdateWrite />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<Main />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
