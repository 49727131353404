import React, { useState } from "react";
import styled from "styled-components";
import { ColorLightBlack, ColorMainBlue, ColorWhite } from "../../Utiles/Color";
import { Btn } from "../../Assets/css/common";
import { useEffect } from "react";

function TabBtns(props) {
  const { btnInfo, currentTab = 0 } = props;
  const [tabIdx, setTabIdx] = useState(0);
  const onBtnClick = (idx, cbFunc) => {
    setTabIdx(idx);
    cbFunc();
  };

  useEffect(() => {
    setTabIdx(currentTab);
  }, [currentTab]);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <TabBtnWrap>
          {btnInfo?.map((e, i) => (
            <TabBtn
              key={i}
              onClick={() => onBtnClick(i, e.func)}
              className={tabIdx === i ? "active" : ""}
            >
              {e.name}
            </TabBtn>
          ))}
          <TabBg idx={tabIdx} />
        </TabBtnWrap>
      </div>
    </>
  );
}

const TabBtnWrap = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 1px solid ${ColorMainBlue};
  border-radius: 24px;
  background-color: ${ColorWhite};
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.1);
`;
const TabBtn = styled(Btn)`
  position: relative;
  width: 118px;
  height: 42px;
  font-size: 14px;
  color: ${ColorLightBlack};
  z-index: 5;
  transition: color 0.5s;

  &.active {
    color: ${ColorWhite};
    font-weight: 600;
    border-radius: 24px;
  }

  @media all and (max-width: 767px) {
    width: 94px;
    height: 34px;
    font-size: 12px;
  }
`;
const TabBg = styled.div`
  position: absolute;
  top: 2px;
  left: ${(props) => `${118 * props.idx + 2}px`};
  width: 118px;
  height: calc(100% - 4px);
  border-radius: 24px;
  background-color: ${ColorMainBlue};
  transition: left 0.5s;

  @media all and (max-width: 767px) {
    width: 94px;
    left: ${(props) => `${94 * props.idx + 2}px`};
  }
`;

export default TabBtns;
