import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import styled from "styled-components";
import { ColorBgGray, ColorMainBlue } from "../../../Utiles/Color";
import { Btn, Container700, FlexBox, Text } from "../../../Assets/css/common";
import { API } from "../../../Utiles/API";
import { tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import TextInput from "../../../Components/Input/TextInput";
import Editor from "../../../Components/Editor";
import FileInput from "../../../Components/Input/FileInput";
import ConfirmModal from "../../../Components/ConfirmModal";
import Footer from "../../../Components/Footer";

// 고객센터 > 1:1 문의 > 등록

function InquiryWrite() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const tokenuser = tkInfo.tokenuser;
  const navigate = useNavigate();
  // * 제목, 내용
  const [values, setValues] = useState({
    title: "",
    files: "",
  });
  const [contValue, setContValue] = useState("");
  const [fileValue, setFileValue] = useState("");
  const [inputModal, setInputModal] = useState(false);
  const [registModal, setRegistmModal] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };
  const handleFileChange = (id, val) => {
    setValues({ ...values, [id]: val });
  };
  const goInquiryList = () => navigate("/customer/inquiry");

  const onSubmit = (e) => {
    e.preventDefault();
    const { title } = values;

    if (!(title && contValue)) {
      setInputModal(true);
    } else {
      // if (regScript.test(contValue))
      //   return alert("잘못된 형식입니다. 다시 입력해주세요.");
      setRegistmModal(true);
    }
  };

  //========== API
  const postInquiryItem = async () => {
    if (tokenuser) {
      const cont_clean = DOMPurify.sanitize(contValue);
      let data = {
        ...values,
        userid: tokenuser,
        contents: cont_clean,
      };
      if (fileValue) data = { ...data, filepath: fileValue };
      const formData = JSON.stringify(data);
      await API.post("/qna", formData);
      goInquiryList();
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    }
  });

  return (
    <>
      <Header subTitle={"1:1 문의"} />
      <Wrapper>
        <Container700>
          <Text
            as="h3"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block mb-7.5"
          >
            1:1 문의
          </Text>
          <form onSubmit={onSubmit}>
            <FlexBox direction={"column"} gap={"20px"}>
              <TextInput
                type={"text"}
                phText={"제목"}
                id={"title"}
                value={values.title}
                onChange={handleChange}
              />
              <Editor value={contValue} setValue={setContValue} />
              <FileInput
                phText={"파일 업로드"}
                id={"files"}
                value={values.files}
                onChange={handleFileChange}
                setFile={setFileValue}
              />
            </FlexBox>
            <Btn
              type="submit"
              size={"16px"}
              color={"#fff"}
              weight={"700"}
              width={"100%"}
              padding={"12px 0"}
              radius={"6px"}
              bgColor={ColorMainBlue}
              className="mt-10"
            >
              문의하기
            </Btn>
          </form>
        </Container700>
      </Wrapper>
      <Footer />
      {inputModal && (
        <ConfirmModal
          text={
            <>
              필수 입력 항목<span style={{ color: ColorMainBlue }}>*</span>을
              입력해주세요.
            </>
          }
          onSubmit={() => setInputModal(false)}
        />
      )}
      {registModal && (
        <ConfirmModal
          text={"문의글을 등록하시겠습니까?"}
          onCancel={() => setRegistmModal(false)}
          onSubmit={postInquiryItem}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 60px 0 150px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;

export default InquiryWrite;
