import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ColorBgGray,
  ColorGray,
  ColorLightGray,
  ColorMainBlue,
  ColorWhite,
} from "../../../Utiles/Color";
import {
  Btn,
  Container700,
  FlexBox,
  LoadingFull,
  Text,
} from "../../../Assets/css/common";
import { API } from "../../../Utiles/API";
import { tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import TextInput from "../../../Components/Input/TextInput";
import Select from "../../../Components/Input/Select";
import Loading from "../../../Components/Loading";
import ConfirmModal from "../../../Components/ConfirmModal";
import Footer from "../../../Components/Footer";

// 고객센터 > 사용설명서 > 동영상 > 등록, 수정

const dockind = [
  {
    name: "카테고리 선택",
    value: "",
  },
  {
    name: "발주관리",
    value: "A1",
  },
  {
    name: "입고관리",
    value: "B1",
  },
  {
    name: "출고관리",
    value: "C1",
  },
  {
    name: "재고관리",
    value: "D1",
  },
  {
    name: "통계관리",
    value: "E1",
  },
  {
    name: "물류설정",
    value: "F1",
  },
];

function VideoWrite() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  // const tokenrole = tkInfo.tokenrole;
  const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const params = useParams();
  const paramsIdx = params?.idx;
  // * 제목, 카테고리, 동영상 URL
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    type: "1",
    contents: "",
    dockind: "",
    url: "",
  });
  const [videoPreview, setVideoPreview] = useState("");
  const [inputModal, setInputModal] = useState(false);
  const [registModal, setRegistmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };
  const goVideoList = () => navigate("/customer/guide/video");

  const onSubmit = (e) => {
    e.preventDefault();
    const { contents, dockind, url } = values;

    if (!(contents && dockind && url)) {
      setInputModal(true);
    } else {
      paramsIdx ? setEditModal(true) : setRegistmModal(true);
    }
  };

  //========== API
  const makeFormData = () => {
    const formData = JSON.stringify(values);
    return formData;
  };
  const postVideoItem = async () => {
    const formData = makeFormData();
    await API.post("/manual", formData);
    goVideoList();
  };
  const putVideoItem = async () => {
    const formData = makeFormData();
    await API.put(`/manual/${paramsIdx}`, formData);
    goVideoList();
  };
  const deleteVideoItem = async () => {
    await API.delete(`/manual/${paramsIdx}`);
    goVideoList();
  };
  const getVideoItem = async () => {
    setLoading(true);
    const res = await API.get(`/manual/${paramsIdx}`);
    const resData = res.data;
    // console.log(resData);
    if (resData) {
      let valueData = {
        ...values,
        contents: resData.contents,
        dockind: resData.dockind,
        url: resData.url,
      };
      setValues(valueData);
      setLoading(false);
    } else {
      alert("존재하지 않는 게시물입니다.");
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      if (tokenrole !== "ROLE_ADMIN") {
        alert("권한이 없습니다.");
        navigate("/");
      } else {
        if (paramsIdx) {
          getVideoItem();
        }
      }
    }
  }, []);
  useEffect(() => {
    // 예시) https://youtu.be/BcbmFxbdsJ0
    const YOUTUBE_ID = values.url?.split("youtu.be/")[1];
    if (YOUTUBE_ID) {
      // const imgSrc = `https://img.youtube.com/vi/${YOUTUBE_ID}/0.jpg`;
      // 0.jpg, default.jpg, mqdefault.jpg, maxresdefault.jpg
      setVideoPreview(YOUTUBE_ID);
    } else {
      setVideoPreview("");
    }
  }, [values.url]);

  return (
    <>
      <Header subcontents={"사용설명서 동영상"} />
      {loading ? (
        <LoadingFull>
          <Loading />
        </LoadingFull>
      ) : (
        ""
      )}
      <Wrapper>
        <Container700>
          <Text
            as="h3"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block mb-7.5"
          >
            사용설명서 동영상
          </Text>
          <form onSubmit={onSubmit}>
            <FlexBox direction={"column"} gap={"20px"}>
              <TextInput
                type={"text"}
                phText={"제목"}
                id={"contents"}
                value={values.contents}
                onChange={handleChange}
              />
              <Select
                options={dockind}
                value={values.dockind}
                id={"dockind"}
                onChange={handleChange}
              />
              <TextInput
                type={"text"}
                phText={"동영상 URL"}
                id={"url"}
                value={values.url}
                onChange={handleChange}
              />
              <VideoImg>
                {videoPreview ? (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoPreview}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <Text
                    size={"16px"}
                    color={ColorGray}
                    weight={"700"}
                    align={"center"}
                  >
                    동영상 미리보기
                  </Text>
                )}
              </VideoImg>
            </FlexBox>
            {paramsIdx ? (
              <>
                {/* 관리자 수정 시 */}
                <FlexBox gap={"10px"} className="mt-10">
                  <Btn
                    type="button"
                    size={"16px"}
                    color={"#fff"}
                    weight={"700"}
                    width={"100%"}
                    padding={"12px 0"}
                    radius={"6px"}
                    bgColor={ColorGray}
                    onClick={() => setDeleteModal(true)}
                  >
                    삭 제
                  </Btn>
                  <Btn
                    type="submit"
                    size={"16px"}
                    color={"#fff"}
                    weight={"700"}
                    width={"100%"}
                    padding={"12px 0"}
                    radius={"6px"}
                    bgColor={ColorMainBlue}
                  >
                    수 정
                  </Btn>
                </FlexBox>
              </>
            ) : (
              <>
                {/* 회원 등록 시 */}
                <Btn
                  type="submit"
                  size={"16px"}
                  color={"#fff"}
                  weight={"700"}
                  width={"100%"}
                  padding={"12px 0"}
                  radius={"6px"}
                  bgColor={ColorMainBlue}
                  className="mt-10"
                >
                  등 록
                </Btn>
              </>
            )}
          </form>
        </Container700>
      </Wrapper>
      <Footer />
      {inputModal && (
        <ConfirmModal
          text={
            <>
              필수 입력 항목<span style={{ color: ColorMainBlue }}>*</span>을
              입력해주세요.
            </>
          }
          onSubmit={() => setInputModal(false)}
        />
      )}
      {registModal && (
        <ConfirmModal
          text={"사용설명서 동영상을 등록하시겠습니까?"}
          onCancel={() => setRegistmModal(false)}
          onSubmit={postVideoItem}
        />
      )}
      {editModal && (
        <ConfirmModal
          text={"사용설명서 동영상을 수정하시겠습니까?"}
          onCancel={() => setEditModal(false)}
          onSubmit={putVideoItem}
        />
      )}
      {deleteModal && (
        <ConfirmModal
          text={"사용설명서 동영상을 삭제하시겠습니까?"}
          onCancel={() => setDeleteModal(false)}
          onSubmit={deleteVideoItem}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 60px 0 150px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;
const VideoImg = styled.div`
  position: relative;
  padding-bottom: 60%;
  border: 1px solid ${ColorLightGray};
  border-radius: 6px;
  background-color: ${ColorWhite};
  overflow: hidden;

  p,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default VideoWrite;
