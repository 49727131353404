import React from "react";
import styled from "styled-components";
import {
  ColorDarkBlack,
  ColorLightGray,
  ColorMainBlue,
} from "../../../Utiles/Color";
import { Text } from "../../../Assets/css/common";
import img_intro1 from "../../../Assets/images/intro_img01.gif";
import img_intro2 from "../../../Assets/images/intro_img02.jpg";
import img_intro3 from "../../../Assets/images/intro_img03.jpg";
import img_intro4 from "../../../Assets/images/intro_img04.jpg";

function IntroStore() {
  return (
    <>
      <div>
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          불편한 발주, 입고는 이제 그만! <br />
          <span style={{ color: ColorMainBlue }}>
            제품 바코드를 이용해 쉽고 편리하게!
          </span>
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img
            src={img_intro1}
            style={{ border: `1px solid ${ColorLightGray}` }}
            alt="제품 바코드로 입고 이미지"
          />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          아직도 팩스나 문자로 발주서를?! <br />
          <span style={{ color: ColorMainBlue }}>
            카카오알림톡으로 발주서를 전달해드릴게요~
          </span>
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro2} alt="알림톡 이미지" />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          <span style={{ color: ColorMainBlue }}>
            발주 전용 사이트를 통하여
          </span>
          <br />
          발주 관리를 편하게!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro3} alt="발주 전용 사이트 이미지" />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          입고 시 고시단가를 바로바로 확인!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro4} alt="고시단가 확인 이미지" />
        </IntroImg>
      </div>
    </>
  );
}

const IntroImg = styled.div`
  img {
    margin: 0 auto;
  }
`;

export default IntroStore;
