import React from "react";
import "./loading.css";

export const Loading = () => {
  return (
    <>
      <div className="loadingio-spinner-rolling-k1wpahvng3">
        <div className="ldio-h7q8r85wa85">
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loading;
