import React from "react";
import styled from "styled-components";
import { ColorBgGray, ColorDarkBlack, ColorMainBlue } from "../../Utiles/Color";
import { Container, FlexBox, Text } from "../../Assets/css/common";
import img_partner1 from "../../Assets/images/partner_logo01.png";
import img_partner2 from "../../Assets/images/partner_logo02.png";
import img_partner3 from "../../Assets/images/partner_logo03.png";
import img_partner4 from "../../Assets/images/partner_logo04.png";
import img_partner5 from "../../Assets/images/partner_logo05.png";
import img_partner6 from "../../Assets/images/partner_logo06.png";
import img_partner7 from "../../Assets/images/partner_logo07.png";
import img_partner8 from "../../Assets/images/partner_logo08.png";
import img_partner9 from "../../Assets/images/partner_logo09.png";
import img_partner10 from "../../Assets/images/partner_logo10.png";
import img_partner11 from "../../Assets/images/partner_logo11.png";
import img_partner12 from "../../Assets/images/partner_logo12.png";
import img_partner13 from "../../Assets/images/partner_logo13.png";
import img_partner14 from "../../Assets/images/partner_logo14.png";
import img_partner15 from "../../Assets/images/partner_logo15.png";
import img_partner16 from "../../Assets/images/partner_logo16.png";
import img_partner17 from "../../Assets/images/partner_logo17.png";
import img_partner18 from "../../Assets/images/partner_logo18.png";
import img_partner19 from "../../Assets/images/partner_logo19.png";
import img_partner20 from "../../Assets/images/partner_logo20.png";
import img_partner21 from "../../Assets/images/partner_logo21.png";
import img_partner22 from "../../Assets/images/partner_logo22.png";
import img_partner23 from "../../Assets/images/partner_logo23.png";
import img_partner24 from "../../Assets/images/partner_logo24.png";
import img_partner25 from "../../Assets/images/partner_logo25.png";
import img_partner26 from "../../Assets/images/partner_logo26.png";
import img_partner27 from "../../Assets/images/partner_logo27.png";

function MainPartner() {
  return (
    <>
      <Wrapper>
        <Container>
          <Text
            as="h3"
            color={ColorDarkBlack}
            weight={"700"}
            align={"center"}
            className="mb-7.5 md:mb-12 text-1.5xl md:text-4xl lg:text-5xl break-keep"
          >
            함께 하고 있는 <span style={{ color: ColorMainBlue }}>병원</span>
            입니다.
          </Text>
          <PartnerWrap>
            <PartnerList>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner1} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner2} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner3} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner4} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner5} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner6} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner7} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner8} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner9} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner10} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner11} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner12} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner13} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner14} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner15} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner16} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner17} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner18} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner19} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner20} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner21} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner22} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner23} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner24} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner25} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>
              <PartnerGroup>
                <PartnerItem>
                  <img src={img_partner26} alt="병원 logo" />
                </PartnerItem>
                <PartnerItem>
                  <img src={img_partner27} alt="병원 logo" />
                </PartnerItem>
              </PartnerGroup>              
            </PartnerList>
          </PartnerWrap>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 120px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 30px 0;
  }
`;
const PartnerWrap = styled.div`
  @media all and (max-width: 767px) {
    padding-bottom: 20px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
const PartnerList = styled(FlexBox)`
  flex-direction: column;
  gap: 30px;

  @media all and (max-width: 767px) {
    flex-direction: row;
    gap: 30px;
  }
`;
const PartnerGroup = styled(FlexBox)`
  gap: 70px;

  @media all and (max-width: 1023px) {
    gap: 20px;
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
`;
const PartnerItem = styled.div`
  width: calc(20% - 56px);

  img {
    width: 100%;
  }

  @media all and (max-width: 1023px) {
    width: calc(20% - 16px);
  }
  @media all and (max-width: 767px) {
    min-width: 172px;
    width: 100%;
  }
`;

export default MainPartner;
