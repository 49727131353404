import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ColorBgGray,
  ColorDarkBlack,
  ColorLightBlack,
  ColorMainBlue,
  ColorWhite,
} from "../../Utiles/Color";
import { Btn, Container, Text } from "../../Assets/css/common";
import "./visual.css";
import ico_plus from "../../Assets/images/ico_plus.png";
import img_visual from "../../Assets/images/main_visual.png";
import img_cmt1 from "../../Assets/images/main_cmt_img01.png";
import img_cmt2 from "../../Assets/images/main_cmt_img02.png";
import img_cmt3 from "../../Assets/images/main_cmt_img03.png";

function MainVisual() {
  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <Container>
          <Text
            as="h2"
            color={ColorDarkBlack}
            weight={"700"}
            align={"center"}
            className="mb-4 md:mb-5 text-1.5xl md:text-3.3xl lg:text-5xl break-keep"
          >
            병원 물류관리 혁신의 시작 <br />
            <Text
              as="span"
              color={ColorMainBlue}
              className="text-2.6xl md:text-4.5xl lg:text-6xl"
            >
              Canon ERP와 함께!
            </Text>
          </Text>
          <Text
            color={ColorLightBlack}
            align={"center"}
            className="mb-6 text-xs md:text-lg font-normal md:font-semibold break-keep"
          >
            병원 업무 특성을 반영한 <br className="block md:hidden" />
            다양한 전문 솔루션을 제공합니다.
          </Text>
          <DetailBtn
            color={ColorWhite}
            bgColor={ColorMainBlue}
            radius={"24px"}
            className="text-xs md:text-base font-semibold md:font-bold"
            onClick={() => navigate("/erp/intro")}
          >
            자세히 보기{" "}
            <img src={ico_plus} className="w-3.5 md:w-5.5" alt="plus" />
          </DetailBtn>
          <div className="main_visual">
            <img src={img_visual} alt="메인 비주얼" />
            <ul>
              <li>
                <img src={img_cmt1} alt="deco" />
              </li>
              <li>
                <img src={img_cmt1} alt="deco" />
              </li>
              <li>
                <img src={img_cmt1} alt="deco" />
              </li>
              <li>
                <img src={img_cmt2} alt="deco" />
              </li>
              <li>
                <img src={img_cmt3} alt="deco" />
              </li>
              <li>
                <img src={img_cmt2} alt="deco" />
              </li>
              <li>
                <img src={img_cmt3} alt="deco" />
              </li>
              <li>
                <img src={img_cmt3} alt="deco" />
              </li>
              <li>
                <img src={img_cmt3} alt="deco" />
              </li>
            </ul>
          </div>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 0;
  background-color: ${ColorBgGray};
  overflow: hidden;

  @media all and (max-width: 767px) {
    padding: 30px 0 0;
  }
`;
const DetailBtn = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 160px;
  padding: 12px 0;
  margin: 0 auto 40px;
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);

  @media all and (max-width: 767px) {
    width: 120px;
  }
`;

export default MainVisual;
