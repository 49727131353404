import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ColorBlack,
  ColorLightBlack,
  ColorLightGray,
  ColorMainBlue,
  ColorWhite,
} from "../../../Utiles/Color";
import {
  Btn,
  Container,
  Table,
  Td,
  Text,
  Th,
} from "../../../Assets/css/common";
import ico_arr from "../../../Assets/images/arr02.png";
import { API } from "../../../Utiles/API";
import { formatDotYmd, tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import Pagination from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import Footer from "../../../Components/Footer";

// 다운로드 > 업데이트 내역

function DownUpdate() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const tokenrole = tkInfo.tokenrole;
  // const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const limit = 10;
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);

  //========== API
  const getUpdateList = async () => {
    const res = await API.get("/updatehistory");
    const resData = res.data.updatehistory;
    const filterData = resData.filter((item) => {
      if (item.gbdc !== "DC") return item;
    });
    // console.log(resData, filterData);
    setTotalData(filterData);
    setData(showListPc(filterData, page, limit));
    setLoading(false);
  };
  const showListPc = (data, page, limit) => {
    const idxLast = page * limit;
    const idxFirst = idxLast - limit;
    const currentItems = data.slice(idxFirst, idxLast);
    return currentItems;
  };
  const showListMobile = (data, page, limit) => {
    const idxLast = page * limit;
    const showItems = data.slice(0, idxLast);
    return showItems;
  };
  const moreBtnClick = () => {
    setPage((state) => (state += 1));
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      getUpdateList();
    }
  }, []);
  useEffect(() => {
    const totalPage = Math.ceil(totalData?.length / limit);
    if (totalPage <= page) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [data]);
  useEffect(() => {
    const winW = window.innerWidth;

    setData();
    if (winW > 767) {
      setData(showListPc(totalData, page, limit));
    } else {
      setData(showListMobile(totalData, page, limit));
    }
  }, [page]);

  return (
    <>
      <Header title={"업데이트 내역"} />
      <Wrapper>
        <Container>
          <Text
            as="h3"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block mb-10"
          >
            업데이트 내역
          </Text>
          <Table className="block md:table">
            <colgroup className="hidden md:table-column-group">
              <col style={{ width: "10.5%" }} />
              <col />
              <col style={{ width: "14%" }} />
            </colgroup>
            <thead className="hidden md:table-header-group">
              <tr>
                <Th size={"16px"} bgColor={ColorLightGray}>
                  No
                </Th>
                <Th size={"16px"} bgColor={ColorLightGray}>
                  제목
                </Th>
                <Th size={"16px"} bgColor={ColorLightGray}>
                  작성일
                </Th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {loading ? (
                <tr className="block md:table-row">
                  <TdLoad colSpan="3" className="block md:table-cell">
                    <Loading />
                  </TdLoad>
                </tr>
              ) : (
                data?.length === 0 && (
                  <tr className="block md:table-row">
                    <TdNone colSpan="3" className="block md:table-cell">
                      <Text className="text-xs md:text-sm">
                        등록된 업데이트 내역이 없습니다.
                      </Text>
                    </TdNone>
                  </tr>
                )
              )}
              {data?.map((e) => {
                const date = formatDotYmd(e.createdate);

                return (
                  <tr key={e.idx} className="block md:table-row">
                    {/* pc */}
                    <Td className="hidden md:table-cell">
                      <Text size={"14px"}>{e.idx}</Text>
                    </Td>
                    <Td align={"left"} className="hidden md:table-cell">
                      <Btn
                        style={{ maxWidth: "100%" }}
                        onClick={() => {
                          const pageLink =
                            tokenrole === "ROLE_ADMIN"
                              ? `/download/update/write/${e.idx}`
                              : `/download/update/view/${e.idx}`;
                          navigate(pageLink);
                        }}
                      >
                        <Text
                          size={"14px"}
                          color={ColorBlack}
                          className="ellipsis"
                        >
                          {e.title}
                        </Text>
                      </Btn>
                    </Td>
                    <Td className="hidden md:table-cell">
                      <Text size={"14px"}>{date}</Text>
                    </Td>
                    {/* mobile */}
                    <Tdm className="block md:hidden">
                      <Btn
                        onClick={() => {
                          const pageLink =
                            tokenrole === "ROLE_ADMIN"
                              ? `/download/update/write/${e.idx}`
                              : `/download/update/view/${e.idx}`;
                          navigate(pageLink);
                        }}
                      >
                        <div>
                          <Text
                            size={"12px"}
                            color={ColorBlack}
                            className="ellipsis mb-1"
                          >
                            {e.title}
                          </Text>
                          <Text size={"12px"} color={ColorLightBlack}>
                            {date}
                          </Text>
                        </div>
                        <i>
                          <img src={ico_arr} alt="arrow" />
                        </i>
                      </Btn>
                    </Tdm>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <TableBottom className="mt-7 hidden md:block">
            <Pagination
              limit={limit}
              total={totalData.length}
              page={page}
              setPage={setPage}
            ></Pagination>
            {tokenrole === "ROLE_ADMIN" && (
              <Btn
                size={"14px"}
                color={ColorWhite}
                weight={"600"}
                width={"80px"}
                height={"48px"}
                radius={"4px"}
                bgColor={ColorMainBlue}
                onClick={() => navigate("/download/update/write")}
              >
                등 록
              </Btn>
            )}
          </TableBottom>
          {showMore ? (
            <Btn
              size={"12px"}
              color={ColorMainBlue}
              weight={"600"}
              border={`1px solid ${ColorMainBlue}`}
              radius={"24px"}
              width={"120px"}
              height={"40px"}
              className="block md:hidden mt-7.5 mx-auto"
              onClick={moreBtnClick}
            >
              더보기
            </Btn>
          ) : (
            ""
          )}
          {tokenrole === "ROLE_ADMIN" && (
            <WriteBtn
              className="block md:hidden"
              onClick={() => navigate("/download/update/write")}
            >
              <i></i>
            </WriteBtn>
          )}
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;

const TdLoad = styled(Td)`
  height: 490px;
  vertical-align: top;

  @media all and (max-width: 767px) {
    height: auto;
    padding: 22px 12px;
  }
`;
const TdNone = styled(Td)`
  @media all and (max-width: 767px) {
    padding: 22px 12px;
  }
`;
const Tdm = styled(Td)`
  padding: 0;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 4px 14px 12px;
    text-align: left;

    i {
      display: block;
    }
  }
`;
const TableBottom = styled.div`
  position: relative;
  padding: 12px 0;

  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
const WriteBtn = styled(Btn)`
  position: fixed;
  right: 16px;
  bottom: 40px;
  padding: 11px;
  border-radius: 50%;
  background-color: ${ColorMainBlue};
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);
  z-index: 100;

  i {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${ColorWhite};
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${ColorWhite};
    }
  }
`;

export default DownUpdate;
