import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  FlexBox,
  Text,
  Btn,
  CheckLabel,
  CheckIcon,
} from "../../Assets/css/common";
import {
  ColorMainBlue,
  ColorBgGray,
  ColorLightBlack,
} from "../../Utiles/Color";
import ico_person from "../../Assets/images/ico_person.png";
import ico_lock from "../../Assets/images/ico_lock.png";
import ico_back from "../../Assets/images/ico_back_btn.png";
import { API, authorizationToken } from "../../Utiles/API";
import TextInput from "../../Components/Input/TextInput";
import { useEffect } from "react";

// 로그인

function Login() {
  const navigate = useNavigate();
  const saveid = localStorage.getItem("userid");
  const loginItems = { userid: "", passwd: "" };
  const [check, setCheck] = useState(true);
  const [values, setValues] = useState(loginItems);
  const [msgs, setMsgs] = useState(loginItems);

  const msgReset = () => {
    setMsgs(loginItems);
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
    msgReset();
  };
  const onLogin = async (e) => {
    e.preventDefault();
    const { userid, passwd } = values;

    if (!(userid || passwd)) return;
    try {
      const formData = JSON.stringify(values);
      const res = await API.post("/auth/v1/login2", formData);
      const resData = res.data;
      const token = resData.accessToken;
       //console.log("LoginData", resData);

      if (!token) {
        if (!resData.userKey)
          return setMsgs({
            ...msgs,
            passwd: "로그인 정보에 오류가 있습니다.",
          });
      } else {
        msgReset();
        // API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        authorizationToken(token);
        sessionStorage.setItem("token", token);
        localStorage.setItem("token", token);
        if (check) {
          localStorage.setItem("userid", values.userid);
        } else {
          if(saveid) {
            localStorage.removeItem("userid", values.userid);
          }
        }
        alert(`${resData.hspName}님, 환영합니다.`);
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSaveCheck = () => {
    setCheck((state) => !state);
  };

  const goBack = () => navigate(-1);

  useEffect(() => {
    if(saveid) {
      setValues({ ...values, 'userid': saveid });
    }
  }, []);

  return (
    <>
      <HeaderSub className="flex md:hidden">
        <BackBtn onClick={goBack}>
          <img src={ico_back} alt="이전 페이지" />
        </BackBtn>
      </HeaderSub>
      <Wrapper>
        <LoginWrap>
          <Text
            as="h2"
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="text-1.5xl md:text-4xl"
          >
            로그인
          </Text>
          <form onSubmit={onLogin}>
            <FlexBox
              direction={"column"}
              gap={"20px"}
              className="mt-5 md:mt-7.5"
            >
              <TextInput
                type={"text"}
                phText={"아이디"}
                icon={ico_person}
                id={"userid"}
                value={values.userid}
                onChange={handleChange}
                validMsg={msgs.userid}
              />
              <TextInput
                type={"password"}
                phText={"비밀번호"}
                icon={ico_lock}
                id={"passwd"}
                value={values.passwd}
                onChange={handleChange}
                validMsg={msgs.passwd}
              />
            </FlexBox>
            <FlexBox
              align={"center"}
              justify={"space-between"}
              className="mt-2"
            >
              <CheckLabel htmlFor="idSave">
                <input
                  type="checkbox"
                  id="idSave"
                  checked={check}
                  onChange={onSaveCheck}
                />
                <CheckIcon>
                  <svg viewBox="0 0 24 24">
                    <polyline points="19 7 10 17 5 12" />
                  </svg>
                </CheckIcon>
                <Text size={"14px"} color={ColorLightBlack}>
                  ID 저장
                </Text>
              </CheckLabel>
              <Btn
                type="button"
                size={"14px"}
                weight={"600"}
                onClick={() => navigate("/join")}
              >
                회원가입
              </Btn>
            </FlexBox>
            <Btn
              type="submit"
              size={"16px"}
              color={"#fff"}
              weight={"700"}
              width={"100%"}
              padding={"12px 0"}
              radius={"6px"}
              bgColor={ColorMainBlue}
              className="mt-10"
            >
              로그인
            </Btn>
          </form>
        </LoginWrap>
      </Wrapper>
    </>
  );
}

const HeaderSub = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  height: 56px;
  padding: 0 8px;
  background-color: ${ColorBgGray};
  z-index: 5;
`;
const BackBtn = styled.button`
  padding: 4px;
  img {
    width: 32px;
  }
`;
const Wrapper = styled.div`
  min-height: 100vh;
  padding: 160px 0 100px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 136px 0 60px;
  }
`;
const LoginWrap = styled.div`
  width: 100%;
  max-width: 412px;
  padding: 0 16px;
  margin: 0 auto;
`;

export default Login;
