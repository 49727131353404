import React from "react";
import "./style.css";

function TextInput(props) {
  const { icon, type, id, value, onChange, phText, validMsg, disabled, autoComplete="on" } = props;

  return (
    <div className={["input_custom", icon && "icon_type"].join(" ")}>
      <div className={validMsg && "err_border"}>
        {icon && (
          <i>
            <img src={icon} className="w-6" alt="icon" />
          </i>
        )}
        <input
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          placeholder=" "
          disabled={disabled}
          autoComplete={autoComplete}
        />
        <label htmlFor={id}>{phText}</label>
      </div>
      {validMsg && <p className="valid_msg">{validMsg}</p>}
    </div>
  );
}

export default TextInput;
