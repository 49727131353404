import React, { useState } from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";
import {
  Btn,
  CheckIcon,
  CheckLabel,
  FlexBox,
  Text,
} from "../../../Assets/css/common";
import { ColorLightBlack, ColorMainBlue } from "../../../Utiles/Color";
import TextInput from "../../../Components/Input/TextInput";
import ico_building from "../../../Assets/images/ico_building.png";
import ico_people from "../../../Assets/images/ico_people.png";
import ico_person from "../../../Assets/images/ico_person.png";
import ico_monitor from "../../../Assets/images/ico_monitor.png";
import ico_mail from "../../../Assets/images/ico_mail.png";
import ico_phone from "../../../Assets/images/ico_phone.png";
import { regEmail, regPhone } from "../../../Utiles/Reg";
import { API } from "../../../Utiles/API";
import { autoHypenPhone } from "../../../Utiles/common";
import AgreeModal from "../../../Components/AgreeModal";
import ConfirmModal from "../../../Components/ConfirmModal";

function ContactConts() {
  // * 병원명, 담당자명, 휴대폰 번호
  const contactItems = {
    corpname: "",
    people: "",
    name: "",
    program: "",
    email: "",
    phone: "",
  };
  const [values, setValues] = useState(contactItems);
  const { corpname, people, name, program, email, phone } = values;
  const [msgs, setMsgs] = useState(contactItems);
  const [check, setCheck] = useState(false);
  const [agreeModal, setAgreeModal] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);

  const handleChange = (e) => {
    let val = e.target.value;
    if (e.target.id === "phone") {
      val = autoHypenPhone(val);
    }
    setValues({ ...values, [e.target.id]: val });
    msgReset();
  };
  const msgReset = () => setMsgs(contactItems);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!(corpname && name && phone && check)) {
      setInputModal(true);
    } else {
      if (email && !regEmail.test(email))
        return setMsgs({
          ...msgs,
          email: "올바른 이메일 형식이 아닙니다.",
        });
      if (!regPhone.test(phone))
        return setMsgs({
          ...msgs,
          phone: "올바른 전화번호 형식이 아닙니다.",
        });
      // if (
      //   regScript.test(corpname) ||
      //   regScript.test(people) ||
      //   regScript.test(name) ||
      //   regScript.test(program) ||
      //   regScript.test(email) ||
      //   regScript.test(phone)
      // )
      //   return alert("잘못된 형식입니다. 다시 입력해주세요.");
      setSubmitModal(true);
    }
  };
  const onAgreeCheck = () => {
    setCheck((state) => !state);
  };
  const modalSubmit = () => {
    postContact();
  };

  //========== API
  const postContact = async () => {
    try {
      const corpname_clean = DOMPurify.sanitize(corpname);
      const name_clean = DOMPurify.sanitize(name);
      const phone_clean = DOMPurify.sanitize(phone);
      let data = {
        senderEmail: "imedixsoft@naver.com",
        senderName: corpname_clean,
        mailContent: `<p style="font-size:20px"><b>담당자 :</b> ${name_clean}</p>
        <br /><p style="font-size:20px"><b>연락처 :</b> ${phone_clean}</p>`,
      };
      if (email) {
        const email_clean = DOMPurify.sanitize(email);
        const conts =
          data.mailContent +
          `<br /><p style="font-size:20px"><b>회사 이메일 :</b> ${email_clean}</p>`;
        data = { ...data, mailContent: conts };
      }
      if (people) {
        const people_clean = DOMPurify.sanitize(people);
        const conts =
          data.mailContent +
          `<br /><p style="font-size:20px"><b>직원 수 :</b> ${people_clean}</p>`;
        data = { ...data, mailContent: conts };
      }
      if (program) {
        const program_clean = DOMPurify.sanitize(program);
        const conts =
          data.mailContent +
          `<br /><p style="font-size:20px"><b>OCS/EMR 프로그램 명칭 :</b> ${program_clean}</p>`;
        data = { ...data, mailContent: conts };
      }
      console.log(data);
      const formData = JSON.stringify(data);
      const res = await API.post("/mailsend", formData);
      const resData = res.data;
      if (resData) {
        alert("SUCCESS : 문의 전송 성공!");
        window.location.replace("/service/contact");
      } else {
        alert("ERROR : 문의 전송 실패!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Text
        as="h3"
        color={ColorMainBlue}
        weight={"700"}
        align={"center"}
        className="text-lg md:text-3xl lg:text-4xl break-keep"
      >
        ERP가 우리 회사에 적합할까요?
      </Text>
      <Text
        color={ColorLightBlack}
        align={"center"}
        className="mt-2.5 text-xs md:text-base"
      >
        ERP 컨설턴트가 맞춤 무료 데모로 안내해드립니다.
      </Text>
      <ContactWrap>
        <form onSubmit={onSubmit}>
          <FlexBox direction={"column"} gap={"20px"}>
            <TextInput
              type={"text"}
              phText={
                <>
                  병원명<span style={{ color: ColorMainBlue }}>*</span>
                </>
              }
              icon={ico_building}
              id={"corpname"}
              value={values.corpname}
              onChange={handleChange}
            />
            <TextInput
              type={"text"}
              phText={"직원 수"}
              icon={ico_people}
              id={"people"}
              value={values.people}
              onChange={handleChange}
            />
            <TextInput
              type={"text"}
              phText={
                <>
                  담당자명<span style={{ color: ColorMainBlue }}>*</span>
                </>
              }
              icon={ico_person}
              id={"name"}
              value={values.name}
              onChange={handleChange}
            />
            <TextInput
              type={"text"}
              phText={"OCS/EMR 프로그램 명칭"}
              icon={ico_monitor}
              id={"program"}
              value={values.program}
              onChange={handleChange}
            />
            <TextInput
              type={"text"}
              phText={"회사 이메일"}
              icon={ico_mail}
              id={"email"}
              value={values.email}
              onChange={handleChange}
              validMsg={msgs.email}
            />
            <TextInput
              type={"text"}
              phText={
                <>
                  휴대폰 번호<span style={{ color: ColorMainBlue }}>*</span>
                </>
              }
              icon={ico_phone}
              id={"phone"}
              value={values.phone}
              onChange={handleChange}
              validMsg={msgs.phone}
            />
            <FlexBox gap={"10px"}>
              <CheckLabel htmlFor="idSave">
                <input
                  type="checkbox"
                  id="idSave"
                  checked={check}
                  onChange={onAgreeCheck}
                />
                <CheckIcon>
                  <svg viewBox="0 0 24 24">
                    <polyline points="19 7 10 17 5 12" />
                  </svg>
                </CheckIcon>
              </CheckLabel>
              <Btn
                type="button"
                size={"14px"}
                color={ColorLightBlack}
                weight={"600"}
                onClick={() => {
                  setCheck(true);
                  setAgreeModal(true);
                }}
              >
                개인정보 수집 및 이용 동의{" "}
                <span className="font-normal" style={{ color: ColorMainBlue }}>
                  [필수]
                </span>
              </Btn>
            </FlexBox>
          </FlexBox>
          <Btn
            type="submit"
            size={"16px"}
            color={"#fff"}
            weight={"700"}
            width={"100%"}
            padding={"12px 0"}
            radius={"6px"}
            bgColor={ColorMainBlue}
            className="mt-10"
          >
            문의하기
          </Btn>
        </form>
      </ContactWrap>
      {agreeModal && <AgreeModal onSubmit={() => setAgreeModal(false)} />}
      {inputModal && (
        <ConfirmModal
          text={
            <>
              필수 입력 항목<span style={{ color: ColorMainBlue }}>*</span>을
              입력해주세요.
            </>
          }
          onSubmit={() => setInputModal(false)}
        />
      )}
      {submitModal && (
        <ConfirmModal
          text={"담당자 문의를 완료하시겠습니까?"}
          onSubmit={modalSubmit}
          onCancel={() => setSubmitModal(false)}
        />
      )}
    </>
  );
}

const ContactWrap = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 30px auto 0;
`;

export default ContactConts;
