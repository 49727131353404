import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ColorMainBlue } from "../../../Utiles/Color";
import { Container, Text } from "../../../Assets/css/common";
import { tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import TabBtns from "../../../Components/TabBtns";
import Select from "../../../Components/Input/Select";
import GuideVideo from "./GuideVideo";
import GuideDocs from "./GuideDocs";
import Footer from "../../../Components/Footer";

// const URL = "https://cream-jersey-b99.notion.site/";
const NOTION_PAGE_ID = "ef768484228044519af528e6a52b7edb";

const cate1 = [
  {
    name: "발주관리",
    value: "ef768484228044519af528e6a52b7edb",
  },
  {
    name: "입고관리",
    value: "e6562cc326e74ffda45cdec225c6095b",
  },
  {
    name: "출고관리",
    value: "dbb3d14264034cc1b6c2e25bf747f01d",
  },
  {
    name: "재고관리",
    value: "a70c195486274330b21afeceea854f2f",
  },
  {
    name: "통계관리",
    value: "d5c3cca7d53e46b4ad7e48d34927d4e0",
  },
  {
    name: "물류설정",
    value: "3959c21f8e334488a89505f798197659",
  },
];
const cate2 = [
  {
    name: "카테고리 선택",
    value: "",
  },
  {
    name: "발주관리",
    value: "A1",
  },
  {
    name: "입고관리",
    value: "B1",
  },
  {
    name: "출고관리",
    value: "C1",
  },
  {
    name: "재고관리",
    value: "D1",
  },
  {
    name: "통계관리",
    value: "E1",
  },
  {
    name: "물류설정",
    value: "F1",
  },
];

function CustomerGuide() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const location = useLocation();
  const navigate = useNavigate();
  const [tabBtn, setTabBtn] = useState([]);
  const [tabIdx, setTabIdx] = useState(0);
  const [npId, setNpId] = useState(NOTION_PAGE_ID);
  const [docKind, setDocKind] = useState("");

  const handleChange1 = (e) => {
    setNpId(e.target.value);
    navigate(`/customer/guide/docs/${e.target.value}`);
  };
  const handleChange2 = (e) => {
    setDocKind(e.target.value);
    navigate(`/customer/guide/video`);
  };

  useEffect(() => {
    const locationSplit = location.pathname.split("/");
    const changeId = locationSplit[locationSplit.length - 1];

    if (changeId === "guide" || changeId === "") {
      setNpId(NOTION_PAGE_ID);
    } else if (changeId === "video") {
      // console.log(changeId);
      setTabIdx(1);
    } else {
      setNpId(changeId);
    }
  }, [location]);
  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    }
    setTabBtn([
      {
        name: "문 서",
        func: () => {
          navigate("/customer/guide");
          setTabIdx(0);
        },
      },
      {
        name: "동영상",
        func: () => {
          navigate("/customer/guide/video");
          setTabIdx(1);
        },
      },
    ]);
  }, []);

  return (
    <>
      <Header title={"사용설명서"} />
      <Wrapper>
        <Container>
          <Text
            as="h3"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block mb-7.5"
          >
            사용설명서
          </Text>
          <GuideTab>
            <TabBtns btnInfo={tabBtn} currentTab={tabIdx} />
            {tabIdx === 0 ? (
              <Select
                options={cate1}
                id={"cate1"}
                value={npId}
                onChange={handleChange1}
              />
            ) : (
              <Select
                options={cate2}
                id={"cate2"}
                value={docKind}
                onChange={handleChange2}
              />
            )}
          </GuideTab>
          <div className="pt-7.5 md:pt-15">
            {tabIdx === 0 ? <GuideDocs npId={npId} /> : ""}
            {tabIdx === 1 ? <GuideVideo docKind={docKind} /> : ""}
          </div>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;
const GuideTab = styled.div`
  position: relative;
  text-align: center;

  select {
    position: absolute;
    top: 0;
    right: 0;
    width: 132px;
  }

  @media all and (max-width: 767px) {
    select {
      position: static;
      width: 100%;
      margin-top: 20px;
    }
  }
`;

export default CustomerGuide;
