import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ColorBgGray,
  ColorDarkBlack,
  ColorDarkGray,
  ColorGray,
  ColorLightBlack,
  ColorMainBlue,
  ColorWhite,
} from "../../../Utiles/Color";
import { Btn, FlexBox, Text } from "../../../Assets/css/common";
import ico_pgprev from "../../../Assets/images/arr_page_prev.png";
import ico_pgnext from "../../../Assets/images/arr_page_next.png";
import ico_play from "../../../Assets/images/ico_play.png";
import { API } from "../../../Utiles/API";
import { formatDotYmd, tokenInfo } from "../../../Utiles/common";
import Loading from "../../../Components/Loading";
import Pagination from "../../../Components/Pagination";

// 고객센터 > 사용설명서 > 동영상

function GuideVideo(props) {
  const { docKind } = props;
  const tkInfo = tokenInfo();
  const tokenrole = tkInfo.tokenrole;
  // const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const limit = 9;
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);

  //========== API
  const getVideoList = async () => {
    const res = await API.get("/manual");
    const resData = res.data.manual;
    const filterData = resData.filter((item) => {
      if (item.gbdc !== "DC" && item.type === "1") {
        if (docKind !== "") {
          if (item.dockind === docKind) return item;
        } else {
          return item;
        }
      }
    });
    // console.log(resData, filterData);
    setTotalData(filterData);
    setData(showListPc(filterData, page, limit));
    setLoading(false);
  };
  const showListPc = (data, page, limit) => {
    const idxLast = page * limit;
    const idxFirst = idxLast - limit;
    const showItems = data.slice(idxFirst, idxLast);
    return showItems;
  };
  const showListMobile = (data, page, limit) => {
    const idxLast = page * limit;
    const showItems = data.slice(0, idxLast);
    return showItems;
  };
  const moreBtnClick = () => {
    setPage((state) => (state += 1));
  };

  useEffect(() => {
    getVideoList();
  }, [docKind]);
  useEffect(() => {
    const totalPage = Math.ceil(totalData?.length / limit);
    if (totalPage <= page) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [data]);
  useEffect(() => {
    const winW = window.innerWidth;

    setData();
    if (winW > 767) {
      setData(showListPc(totalData, page, limit));
    } else {
      setData(showListMobile(totalData, page, limit));
    }
  }, [page]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        data?.length === 0 && (
          <div className="text-center">
            <Text className="text-xs md:text-sm">
              등록된 동영상이 없습니다.
            </Text>
          </div>
        )
      )}
      <VideoList wrap={"wrap"}>
        {data?.map((e) => {
          const thumbId = e.url?.split("youtu.be/")[1];
          const thumbImg = `https://img.youtube.com/vi/${thumbId}/0.jpg`;
          const date = formatDotYmd(e.createdate);

          return (
            <VideoItem
              key={e.idx}
              onClick={() => {
                const pageLink =
                  tokenrole === "ROLE_ADMIN"
                    ? `/customer/guide/write/${e.idx}`
                    : `/customer/guide/view/${e.idx}`;
                navigate(pageLink);
              }}
            >
              <VideoImg>
                <i>
                  <img src={ico_play} alt="play icon" />
                </i>
                <img src={thumbImg} alt="video img" />
              </VideoImg>
              <VideoText>
                <Text
                  color={ColorLightBlack}
                  className="mb-1 text-xs md:text-sm"
                >
                  {date}
                </Text>
                <FlexBox
                  align={"center"}
                  justify={"space-between"}
                  gap={"16px"}
                >
                  <Text
                    color={ColorDarkBlack}
                    weight={"700"}
                    className="text-xs md:text-lg ellipsis"
                  >
                    {e.contents}
                  </Text>
                  <Text color={ColorGray} className="text-xs md:text-sm">
                    {e.time}
                  </Text>
                </FlexBox>
              </VideoText>
            </VideoItem>
          );
        })}
      </VideoList>
      <TableBottom className="mt-7 hidden md:block">
        <Pagination
          limit={limit}
          total={totalData.length}
          page={page}
          setPage={setPage}
        ></Pagination>
        {tokenrole === "ROLE_ADMIN" && (
          <Btn
            size={"14px"}
            color={ColorWhite}
            weight={"600"}
            width={"80px"}
            height={"48px"}
            radius={"4px"}
            bgColor={ColorMainBlue}
            onClick={() => navigate("/customer/guide/write")}
          >
            등 록
          </Btn>
        )}
      </TableBottom>
      {showMore ? (
        <Btn
          size={"12px"}
          color={ColorMainBlue}
          weight={"600"}
          border={`1px solid ${ColorMainBlue}`}
          radius={"24px"}
          width={"120px"}
          height={"40px"}
          className="block md:hidden mt-7.5 mx-auto"
          onClick={moreBtnClick}
        >
          더보기
        </Btn>
      ) : (
        ""
      )}
      {tokenrole === "ROLE_ADMIN" && (
        <WriteBtn
          className="block md:hidden"
          onClick={() => navigate("/customer/guide/write")}
        >
          <i></i>
        </WriteBtn>
      )}
    </>
  );
}

const VideoList = styled(FlexBox)`
  gap: 40px 24px;

  @media all and (max-width: 767px) {
    gap: 30px;
  }
`;
const VideoItem = styled.div`
  width: calc(33.333333% - 16px);
  border-radius: 6px;
  overflow: hidden;
  background-color: ${ColorBgGray};
  cursor: pointer;

  @media all and (max-width: 1023px) {
    width: calc(50% - 12px);
  }
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;
const VideoImg = styled.div`
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`;
const VideoText = styled.div`
  padding: 10px 20px 20px;

  @media all and (max-width: 767px) {
    padding: 10px 12px 14px;
  }
`;
const TableBottom = styled.div`
  position: relative;
  padding: 12px 0;

  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
const PagerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin ?? props.margin};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: ${ColorDarkGray};

    &.active {
      color: #2d2d2d;
    }
    &.page_prev {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgprev}) no-repeat center center / contain;
      }
    }
    &.page_next {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgnext}) no-repeat center center / contain;
      }
    }
  }
`;
const WriteBtn = styled(Btn)`
  position: fixed;
  right: 16px;
  bottom: 40px;
  padding: 11px;
  border-radius: 50%;
  background-color: ${ColorMainBlue};
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);
  z-index: 100;

  i {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${ColorWhite};
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${ColorWhite};
    }
  }
`;

export default GuideVideo;
