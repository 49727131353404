import React from "react";
import styled from "styled-components";
import {
  ColorGray,
  ColorDarkBlack,
  ColorLightBlack,
  ColorMainBlue,
  ColorWhite,
  ColorLightGray,
} from "../../../Utiles/Color";
import { Container, FlexBox, Text } from "../../../Assets/css/common";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";

function ServicePrice() {
  return (
    <>
      <Header title={"구독 서비스 요금제"} />
      <Wrapper>
        <Container>
          <div className="hidden md:block mb-20">
            <Text
              as="h3"
              size={"36px"}
              color={ColorMainBlue}
              weight={"700"}
              align={"center"}
            >
              구독요금제 안내
            </Text>
            <Text
              size={"16px"}
              color={ColorLightBlack}
              align={"center"}
              className="mt-2.5"
            >
              ERP 컨설턴트가 맞춤 요금제로 안내해드립니다.
            </Text>
          </div>
          <PriceWrap>
            <FlexBox gap={"20px"}>
              <PriceItem>
                <Text
                  color={ColorDarkBlack}
                  align={"center"}
                  className="text-sm md:text-base font-semibold md:font-bold"
                >
                  Lite
                </Text>
                <Text
                  color={ColorLightBlack}
                  align={"center"}
                  className="mt-2.5 text-xs md:text-sm"
                >
                  의원을 위한 요금제
                </Text>
                <Text
                  color={ColorMainBlue}
                  align={"center"}
                  className="mt-2.5 md:mt-5 text-sm md:text-base font-semibold md:font-bold"
                >
                  월 <span className="text-4xl font-bold">33,000</span>원
                </Text>
                <TextLine className="mt-5 md:mt-10 mb-5 md:mb-7.5">
                  <Text color={ColorGray} className="text-xs md:text-sm">
                    주요 기능
                  </Text>
                </TextLine>
                <FlexBox direction={"column"} className="gap-5 md:gap-4">
                  <TextBar
                    color={ColorLightBlack}
                    className="text-xs md:text-sm"
                  >
                    유저 라이선스 3개
                  </TextBar>        
                </FlexBox>
              </PriceItem>
              <PriceItem>
                <Text
                  color={ColorDarkBlack}
                  align={"center"}
                  className="text-sm md:text-base font-semibold md:font-bold"
                >
                  Full
                </Text>
                <Text
                  color={ColorLightBlack}
                  align={"center"}
                  className="mt-2.5 text-xs md:text-sm"
                >
                  물류업무가 복잡한 병원을 위한 요금제
                </Text>
                <Text
                  color={ColorMainBlue}
                  align={"center"}
                  className="mt-2.5 md:mt-5 text-sm md:text-base font-semibold md:font-bold"
                >
                  월 <span className="text-4xl font-bold">99,000</span>원
                </Text>
                <TextLine className="mt-5 md:mt-10 mb-5 md:mb-7.5">
                  <Text color={ColorGray} className="text-xs md:text-sm">
                    주요 기능
                  </Text>
                </TextLine>                
                <FlexBox direction={"column"} className="gap-5 md:gap-4">
                  <TextBar
                    color={ColorLightBlack}
                    className="text-xs md:text-sm"
                  >
                    라이선스 수 제한 없음
                  </TextBar>          
                  <TextLine className="mt-5 md:mt-10 mb-5 md:mb-0.5">
                  <Text color={ColorGray} className="text-xs md:text-sm">
                    유료 옵션
                  </Text>
                </TextLine>                             
                  <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      EMR/OCS 연동 재고관리
                      <span style={{ fontSize: '5px' }}>①</span>
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      카카오 알림톡 서비스
                      <span style={{ fontSize: '5px' }}>②</span>
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      국세청 세금계산서 조회
                      <span style={{ fontSize: '5px' }}>③</span>
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      바코드 입고/출고 지원
                      <span style={{ fontSize: '5px' }}>④</span>
                    </TextBar> 
                </FlexBox>
              </PriceItem>
              <PriceItem>
                <Text
                  color={ColorDarkBlack}
                  align={"center"}
                  className="text-sm md:text-base font-semibold md:font-bold"
                >
                  구축형
                </Text>
                <Text
                  color={ColorLightBlack}
                  align={"center"}
                  className="mt-2.5 text-xs md:text-sm"
                >
                  다양한 통계와 복잡한 업무를 지원
                </Text>
                <Text
                  color={ColorMainBlue}
                  align={"center"}
                  className="mt-2.5 md:mt-5 text-sm md:text-base font-semibold md:font-bold"
                >
                  <span className="text-4xl font-bold">컨설턴트문의</span>
                </Text>
                <TextLine className="mt-5 md:mt-10 mb-5 md:mb-7.5">
                  <Text color={ColorGray} className="text-xs md:text-sm">
                    주요 기능
                  </Text>
                </TextLine>
                <FlexBox direction={"column"} className="gap-5 md:gap-4">
                <TextBar
                    color={ColorLightBlack}
                    className="text-xs md:text-sm"
                  >
                    라이선스 수 제한 없음
                  </TextBar>                     
                  <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      EMR/OCS 연동 재고관리
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      카카오 알림톡 서비스 지원
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      국세청 세금계산서 조회
                    </TextBar>
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      바코드 입고/출고
                    </TextBar> 
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      일부 커스터마이즈 기능 제공
                    </TextBar>        
                    <TextBar
                      color={ColorLightBlack}
                      className="text-xs md:text-sm"
                    >
                      전문 컨설턴트 배정
                    </TextBar>                                   
                </FlexBox>
              </PriceItem>
            </FlexBox>
          </PriceWrap>
          <PriceItem className="mt-2 md:mt-7">
            <FlexBox direction={"column"} className="gap-5 md:gap-4">
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              <span style={{ fontSize: '6px' }}>①</span> EMR/OCS 연동재고 관리 : 의료기관에서 운영중인 EMR/OCS와 연동하여 자동출고하는 기능입니다. 단, EMR/OCS업체에 따라 연동지원이 불가능 할 수 있으며, 당사에서 지정한 연동사와의 별도의 계약이 필요합니다.
              </TextBar>
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              <span style={{ fontSize: '6px' }}>②</span> 카카오알림톡 기능 : 카카오알림톡 서비스를 이용하여 공급사에 알림톡을 발송할 수 있습니다. 카카오알림톡 서비스를 이용하기 위해서는 당사에서 지정한 연동사와의 별도의 계약이 필요합니다.
              </TextBar>
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              <span style={{ fontSize: '6px' }}>③</span> 국세청 세금계산서 조회 기능 : 국세청에 신고된 전자세금계산서 정보를 거래원장과 비교 분석하는 기능을 제공합니다. 해당 기능을 사용하기 위해서는 당사에서 지정한 연동사와의 별도의 계약이 필요합니다.
              </TextBar>
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              <span style={{ fontSize: '6px' }}>④</span> 바코드 입고/출고 지원 : 바코드를 이용하여 입고/출고를 처리할 수 있습니다. 바코드 리더기는 고객 측에서 별도 구매가 필요합니다.
              </TextBar>
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
                연동사 정보 : EMR/OCS 연동, 카카오알림톡, 국세청연동 기능 → 메타데이터랩(대표번호 : 070-4759-4859)
              </TextBar>
              <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
                교육 및 설치 업체 정보 : 클라이언트 설치, 사용자 대상 기능 교육 컨설팅 → (주)쿠즈메드(대표번호 : 070-7869-7789)
              </TextBar>
            </FlexBox>
          </PriceItem>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 40px 0 40px;
  }
`;
const PriceWrap = styled.div`
  @media all and (max-width: 1023px) {
    padding-bottom: 20px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;

    &::-webkit-scrollbar {
      display: none;
    }
    & > div {
      min-width: 940px;
    }
  }
`;
const PriceItem = styled.div`
  flex: 1;
  padding: 50px 30px;
  border: 1px solid ${ColorMainBlue};
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);

  @media all and (max-width: 1023px) {
    padding: 50px 20px 30px;
  }
  @media all and (max-width: 767px) {
    padding: 40px 20px 30px;
  }
`;
const TextLine = styled.div`
  position: relative;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 0.5px);
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${ColorLightGray};
  }
  ${Text} {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    background-color: ${ColorWhite};
  }
`;
const TextBar = styled(Text)`
  position: relative;
  padding-left: 10px;

  &::before {
    content: "-";
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default ServicePrice;
