import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ColorDarkBlack,
  ColorDarkGray,
  ColorLightBlack,
  ColorMainBlue,
} from "../../../Utiles/Color";
import { Btn, Container700, FlexBox, Text } from "../../../Assets/css/common";
import { API } from "../../../Utiles/API";
import { formatDotYmd, tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import Footer from "../../../Components/Footer";

// 고객센터 > 사용설명서 > 동영상 > 상세

function VideoView() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const navigate = useNavigate();
  const params = useParams();
  const paramsIdx = params?.idx;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  //========== API
  const getVideoItem = async () => {
    const res = await API.get(`/manual/${paramsIdx}`);
    const resData = res.data;

    if (resData) {
      const date = formatDotYmd(resData.createdate);
      const ytid = resData.url?.split("youtu.be/")[1];
      const dockindname =
        resData.dockind === "A1"
          ? "발주관리"
          : resData.dockind === "B1"
          ? "입고관리"
          : resData.dockind === "C1"
          ? "출고관리"
          : resData.dockind === "D1"
          ? "재고관리"
          : resData.dockind === "E1"
          ? "통계관리"
          : resData.dockind === "F1"
          ? "물류설정"
          : "";
      let filterData = {
        ...resData,
        createdate: date,
        dockindname,
        ytid,
      };
      // console.log(resData, filterData);
      setData(filterData);
      setLoading(false);
    } else {
      alert("존재하지 않는 게시물입니다.");
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      getVideoItem();
    }
  }, []);

  return (
    <>
      <Header subTitle={"사용설명서 동영상 상세"} />
      <Wrapper>
        <Container700>
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <>
              <Text
                as="h3"
                color={ColorDarkBlack}
                weight={"700"}
                align={"center"}
                className="text-lg md:text-3xl lg:text-4xl break-keep"
              >
                {data.contents}
              </Text>
              <Text
                color={ColorLightBlack}
                align={"center"}
                className="mt-2.5 md:mt-5 text-xs md:text-sm"
              >
                <span style={{ color: ColorMainBlue }}>{data.dockindname}</span>
                {data.dockindname && data.createdate && " | "}
                {data.createdate}
              </Text>
              <VideoWrap className="mt-5 md:mt-10">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${data.ytid}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </VideoWrap>
              <FlexBox justify={"flex-end"} className="mt-12.5 md:mt-15">
                <Btn
                  width={"80px"}
                  height={"48px"}
                  radius={"6px"}
                  border={`1px solid ${ColorDarkGray}`}
                  size={"14px"}
                  color={ColorDarkGray}
                  weight={"600"}
                  onClick={() => navigate("/customer/guide")}
                >
                  목 록
                </Btn>
              </FlexBox>
            </>
          )}
        </Container700>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;
const VideoWrap = styled.div`
  position: relative;
  padding-bottom: 60.5%;
  border-radius: 6px;
  overflow: hidden;

  img,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default VideoView;
