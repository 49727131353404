import React, { useEffect, useState } from "react";
import { NotionRenderer } from "react-notion";
import axios from "axios";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import "./notion.css";

// 고객센터 > 사용설명서 > 문서

function GuideDocs(props) {
  const { npId } = props;
  const [response, setResponse] = useState({});

  useEffect(() => {
    axios
      .get(`https://notion-api.splitbee.io/v1/page/${npId}`)
      .then(({ data }) => {
        setResponse(data);
        // console.log(data);
      });
  }, [npId]);
  useEffect(() => {
    const location = window.location.origin;
    const childObj = document.querySelectorAll(".notion-page-link");
    childObj.forEach((e) => {
      // console.log(location);
      // console.log(e.href);
      const href = `/customer/guide/docs/${e.href.split(`${location}/`)[1]}`;
      e.href = href;
    });
  }, [response]);

  return (
    <>
      {Object.keys(response).length ? (
        <NotionRenderer blockMap={response} fullPage={true} hideHeader={true} />
      ) : (
        ""
      )}
    </>
  );
}

export default GuideDocs;
