import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import { ColorWhite } from "../../Utiles/Color";

function Editor(props) {
  const { value, setValue } = props;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      // ["image", "video"],
      // [{ font: [] }],
      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // ["clean"],
    ],
  };

  return (
    <>
      <div style={{ backgroundColor: ColorWhite }}>
        <ReactQuill
          theme="snow"
          modules={modules}
          value={value}
          onChange={setValue}
          style={{ backgroundColor: ColorWhite }}
        />
      </div>
    </>
  );
}

export default Editor;
