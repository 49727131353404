export const ColorMainBlue = "#4BA9D8";
export const ColorRed = "#E71441";
export const ColorWhite = "#FFFFFF";
export const ColorBgGray = "#F9FAFB";
export const ColorLineGray = "#D1D5DB";
export const ColorLightGray = "#E5E7EB";
export const ColorGray = "#9CA3AF";
export const ColorDarkGray = "#6B7280";
export const ColorLightBlack = "#4B5563";
export const ColorBlack = "#374151";
export const ColorDarkBlack = "#111827";
