import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../../../Assets/css/common";
import Header from "../../../Components/Header";
import TabBtns from "../../../Components/TabBtns";
import IntroStore from "./IntroStore";
import IntroStock from "./IntroStock";
import IntroStatistics from "./IntroStatistics";
import Footer from "../../../Components/Footer";

function ErpIntro() {
  const location = useLocation();
  const [tabBtn, setTabBtn] = useState([]);
  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    const locationSearch = location?.search;
    if(locationSearch) {
      const tabNum = +locationSearch.split("=")[1];
      setTabIdx(tabNum);
    }
  }, [location]);
  useEffect(() => {
    setTabBtn([
      { name: "발주/입고", func: () => setTabIdx(0) },
      { name: "출고/재고 관리", func: () => setTabIdx(1) },
      { name: "통계 관리", func: () => setTabIdx(2) },
    ]);
  }, []);

  return (
    <>
      <Header title={"Cannon ERP 소개"} />
      <Wrapper>
        <Container>
          <TabBtns btnInfo={tabBtn} currentTab={tabIdx} />
          <div className="pt-7.5 md:pt-10">
            {tabIdx === 0 ? <IntroStore /> : ""}
            {tabIdx === 1 ? <IntroStock /> : ""}
            {tabIdx === 2 ? <IntroStatistics /> : ""}
          </div>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 40px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;

export default ErpIntro;
