import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ColorLightBlack, ColorBlack, ColorMainBlue } from "../../Utiles/Color";
import { Container, FlexBox, Text } from "../../Assets/css/common";
import ico_main1 from "../../Assets/images/main_ico01.png";
import ico_main2 from "../../Assets/images/main_ico02.png";
import ico_main3 from "../../Assets/images/main_ico03.png";
import ico_main4 from "../../Assets/images/main_ico04.png";
import ico_arr from "../../Assets/images/arr01.png";

function MainQuick() {
  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <Container>
          <FlexBox className="flex-wrap lg:flex-nowrap gap-3 lg:gap-5">
            <QuickItem onClick={() => navigate("/service/price")}>
              <Text
                color={ColorBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                저렴한 구독형 서비스
              </Text>
              <FlexBox
                align={"flex-end"}
                justify={"space-between"}
                className="mt-0 md:mt-2"
              >
                <img src={ico_main1} alt="저렴한 구독형 서비스" />
                <Text
                  size={"14px"}
                  color={ColorLightBlack}
                  className="hidden md:block"
                >
                  자세히 보기 &gt;
                </Text>
              </FlexBox>
            </QuickItem>
            <QuickItem onClick={() => navigate("/erp/intro")}>
              <Text
                color={ColorBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                편리한 발주/입고 관리
              </Text>
              <FlexBox
                align={"flex-end"}
                justify={"space-between"}
                className="mt-2"
              >
                <img src={ico_main2} alt="편리한 발주/입고 관리" />
                <Text
                  size={"14px"}
                  color={ColorLightBlack}
                  className="hidden md:block"
                >
                  자세히 보기 &gt;
                </Text>
              </FlexBox>
            </QuickItem>
            <QuickItem onClick={() => navigate("/erp/intro?tab=1")}>
              <Text
                color={ColorBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                정확한 출고/재고 관리
              </Text>
              <FlexBox
                align={"flex-end"}
                justify={"space-between"}
                className="mt-2"
              >
                <img src={ico_main3} alt="정확한 출고/재고 관리" />
                <Text
                  size={"14px"}
                  color={ColorLightBlack}
                  className="hidden md:block"
                >
                  자세히 보기 &gt;
                </Text>
              </FlexBox>
            </QuickItem>
            <QuickItem onClick={() => navigate("/erp/intro?tab=2")}>
              <Text
                color={ColorBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                다양한 통계 관리
              </Text>
              <FlexBox
                align={"flex-end"}
                justify={"space-between"}
                className="mt-2"
              >
                <img src={ico_main4} alt="다양한 통계 관리" />
                <Text
                  size={"14px"}
                  color={ColorLightBlack}
                  className="hidden md:block"
                >
                  자세히 보기 &gt;
                </Text>
              </FlexBox>
            </QuickItem>
          </FlexBox>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 120px;

  @media all and (max-width: 767px) {
    padding: 30px 0 50px;
  }
`;
const QuickItem = styled.button`
  position: relative;
  flex: 1;
  padding: 20px;
  border: 1px solid ${ColorMainBlue};
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(2, 103, 154, 0.15);
  text-align: left;

  @media all and (max-width: 1023px) {
    flex: none;
    width: calc(50% - 6px);
  }
  @media all and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 10px;
    width: 100%;
    padding: 13px 20px;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 10px);
      right: 20px;
      display: block;
      width: 20px;
      height: 20px;
      background: url(${ico_arr}) no-repeat center center / contain;
    }
    img {
      width: 40px;
    }
  }
`;

export default MainQuick;
