import React from "react";
import axios from "axios";
import "./style.css";
import { Btn } from "../../Assets/css/common";
import { ColorGray, ColorWhite } from "../../Utiles/Color";
import { shortImgName } from "../../Utiles/common";
import { API } from "../../Utiles/API";

function FileInput(props) {
  const { icon, id, value, onChange, phText, validMsg, setFile } = props;

  const sendImageToServer = async (image) => {
    // 용량 제한
    if (image.size > 1 * 1024 * 1024) {
      alert("용량이 너무 큽니다. 1MB 이하로 등록해주세요.");
      return;
    }
    if (image) {
      const formData = new FormData();
      formData.append("uploadFile", image);
      const res = await axios.post(
        "https://canonerp.com:8080/uploadTest/uploadFormAction",
        formData
      );
      const resData = res.data[0];
      const imgName = shortImgName(resData);
      if (!res) return alert("올바른 파일이 아닙니다");
      setFile(resData);
      onChange(`${id}`, imgName);
      // console.log(`${id}`, resData);
    } else {
      alert("사진을 등록하세요!");
    }
  };

  const handleChange = () => {
    const fileInput = document.getElementById(`${id}2`);
    sendImageToServer(fileInput.files[0]);
  };

  return (
    <div
      className={["input_custom file_custom", icon && "icon_type"].join(" ")}
    >
      <div className={validMsg && "err_border"}>
        {icon && (
          <i>
            <img src={icon} className="w-6" alt="icon" />
          </i>
        )}
        <input
          type="text"
          value={value}
          id={id}
          placeholder=" "
          className="file_name"
          readOnly
        />
        <label>{phText}</label>
        <div className="file_btn">
          <Btn
            size={"14px"}
            color={ColorWhite}
            width={"80px"}
            padding={"14px 0 13px"}
            radius={"6px"}
            bgColor={ColorGray}
          >
            파일 첨부
          </Btn>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            id={`${id}2`}
            className="file_hidden"
            onChange={handleChange}
          />
        </div>
      </div>
      {validMsg && <p className="valid_msg">{validMsg}</p>}
    </div>
  );
}

export default FileInput;
