import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  ColorMainBlue,
  ColorWhite,
  ColorBgGray,
  ColorLightBlack,
} from "../../Utiles/Color";
import { FlexBox, Text } from "../../Assets/css/common";
import img_logo from "../../Assets/images/logo.png";

// HEADER - PC

function HeaderPc(props) {
  const { hdMenu, isLogin, onLogout } = props;
  const location = useLocation();
  const [dep2Show, setDep2Show] = useState(false);
  const path = location.pathname.split("/")[1];

  const onMenuShow = () => setDep2Show(true);
  const onMenuHide = () => setDep2Show(false);

  return (
    <>
      <HeaderWrap
        className={["hidden", "md:block", dep2Show ? "active" : ""].join(" ")}
      >
        <Container align={"flex-start"} justify={"space-between"}>
          <FlexBox>
            <Logo>
              <Link to={"/"}>
                <img src={img_logo} alt="Medical ERP With CanonKorea Logo" />
              </Link>
            </Logo>
            <MenuWrap onMouseEnter={onMenuShow} onMouseLeave={onMenuHide}>
              {hdMenu?.map((e, i) => {
                const link = e.link.split("/")[1];

                return (
                  <Menu key={i} className={path === link && "active"}>
                    <Text
                      as="div"
                      size={"16px"}
                      color={ColorLightBlack}
                      weight={"700"}
                    >
                      <Link to={e.link}>{e.name}</Link>
                    </Text>
                    <MenuDepth2
                      direction={"column"}
                      gap={"30px"}
                      className="depth02"
                    >
                      {e.dep2?.map((eDep2, iDep2) => (
                        <Text
                          key={iDep2}
                          as="div"
                          color={ColorLightBlack}
                          className="text-xs lg:text-sm"
                        >
                          <Link to={eDep2.link}>{eDep2.name}</Link>
                        </Text>
                      ))}
                    </MenuDepth2>
                  </Menu>
                );
              })}
            </MenuWrap>
          </FlexBox>
          <RightMenu align={"center"} gap={"10px"}>
            {isLogin ? (
              <>
                {/* 로그인 시 */}
                <Text as="div" size={"14px"}>
                  <button onClick={onLogout}>로그아웃</button>
                </Text>
                <Text as="div" size={"14px"}>
                  <Link to={"/mypage"}>마이페이지</Link>
                </Text>
              </>
            ) : (
              <>
                {/* 비로그인 시 */}
                <Text as="div" size={"14px"}>
                  <Link to={"/join"}>회원가입</Link>
                </Text>
                <Text as="div" size={"14px"}>
                  <Link to={"/login"}>로그인</Link>
                </Text>
              </>
            )}
          </RightMenu>
        </Container>
      </HeaderWrap>
    </>
  );
}

const HeaderWrap = styled.div`
  position: relative;
  height: 64px;
  overflow: hidden;
  background-color: ${ColorWhite};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 64px;
    background-color: ${ColorBgGray};
  }

  &.active {
    height: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

    .depth02 {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
`;
const Logo = styled.div`
  padding: 12px 0;
  margin-right: 80px;

  img {
    width: 140px;
  }

  @media all and (max-width: 1023px) {
    margin-right: 20px;
  }
`;
const MenuWrap = styled(FlexBox)`
  gap: 80px;

  @media all and (max-width: 1023px) {
    gap: 20px;
  }
`;
const Menu = styled.div`
  a {
    display: block;
    padding: 20px 0;
  }
  &:hover > div > a,
  &.active > div > a {
    color: ${ColorMainBlue};
  }
`;
const MenuDepth2 = styled(FlexBox)`
  padding: 30px 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;

  a {
    padding: 0;
  }
`;
const RightMenu = styled(FlexBox)`
  height: 64px;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 32px;
    font-size: 14px;
    border-radius: 4px;
  }
  div:first-of-type a,
  div:first-of-type button {
    color: ${ColorMainBlue};
    border: 1px solid ${ColorMainBlue};
    background-color: ${ColorWhite};
  }
  div:last-of-type a,
  div:last-of-type button {
    color: ${ColorWhite};
    background-color: ${ColorMainBlue};
  }
`;

export default HeaderPc;
