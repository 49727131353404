import React from "react";
import styled from "styled-components";
import {
  ColorMainBlue,
  ColorLightBlack,
  ColorDarkBlack,
} from "../../Utiles/Color";
import { FlexBox, Text } from "../../Assets/css/common";
import ico_person from "../../Assets/images/ico_blue_person.png";
import ico_calendar from "../../Assets/images/ico_blue_calendar.png";
import ico_money from "../../Assets/images/ico_blue_money.png";
import ico_home from "../../Assets/images/ico_blue_home.png";
import ico_building from "../../Assets/images/ico_blue_building.png";

function MainHr() {
  return (
    <>
      <Wrapper>
        <Text
          as="h3"
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="mb-4 md:mb-5 text-1.5xl md:text-4xl lg:text-5xl break-keep"
        >
          HR 관리부터 <br />
          <span style={{ color: ColorMainBlue }}>자산, 경영지표까지</span>
        </Text>
        <Text
          color={ColorLightBlack}
          align={"center"}
          className="mb-7.5 text-xs md:text-lg font-normal md:font-semibold break-keep"
        >
          병원 업무 특성을 반영한 <br className="block md:hidden" />
          다양한 전문 솔루션을 제공합니다.
        </Text>
        <HrList
          justify={"center"}
          className="flex-wrap lg:flex-nowrap gap-2.5 xl:gap-5"
        >
          <HrItem>
            <FlexBox align={"center"} gap={"4px"} className="mb-2">
              <img src={ico_person} alt="icon" />
              <Text
                color={ColorDarkBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                인사 관리
              </Text>
            </FlexBox>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              병원의 특수한 직종 / 직군에 맞춘 데이터 관리
            </TextBar>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              적용일자 관리로 데이터 추적에 최적화
            </TextBar>
          </HrItem>
          <HrItem>
            <FlexBox align={"center"} gap={"4px"} className="mb-2">
              <img src={ico_calendar} alt="icon" />
              <Text
                color={ColorDarkBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                근태 관리
              </Text>
            </FlexBox>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              부서별 / 직종별에 최적화하여 근무표 작성
            </TextBar>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              대리결재 / 대리작성 등 병원 특성에 맞춤 기능 제공
            </TextBar>
          </HrItem>
          <HrItem>
            <FlexBox align={"center"} gap={"4px"} className="mb-2">
              <img src={ico_money} alt="icon" />
              <Text
                color={ColorDarkBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                급여 관리
              </Text>
            </FlexBox>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              한번의 세팅으로 일괄 급여 생성
            </TextBar>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              근로기준법에 맞춰 최신 출력물 제공(계산식)
            </TextBar>
          </HrItem>
          <HrItem>
            <FlexBox align={"center"} gap={"4px"} className="mb-2">
              <img src={ico_home} alt="icon" />
              <Text
                color={ColorDarkBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                자산 관리
              </Text>
            </FlexBox>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              QR코드와 모바일앱을 통한 고정자산 관리
            </TextBar>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              점검일시 및 실사 주기를 Push 알림 서비스 제공
            </TextBar>
          </HrItem>
          <HrItem>
            <FlexBox align={"center"} gap={"4px"} className="mb-2">
              <img src={ico_building} alt="icon" />
              <Text
                color={ColorDarkBlack}
                weight={"700"}
                className="text-sm md:text-base"
              >
                경영 정보
              </Text>
            </FlexBox>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              EMR / ERP와 연동으로 시각화된 자료를 실시간 서비스 제공
            </TextBar>
            <TextBar color={ColorLightBlack} className="text-xs md:text-sm">
              각종 메일, 매출, 근로자 등 다양한 정보 제공
            </TextBar>
          </HrItem>
        </HrList>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 120px 0;

  @media all and (max-width: 767px) {
    padding: 50px 0;
  }
`;
const HrList = styled(FlexBox)`
  width: 100%;
  max-width: 1612px;
  padding: 0 16px;
  margin: 0 auto;
`;
const HrItem = styled.div`
  flex: 1;
  padding: 17px 18px 18px;
  border: 2px solid ${ColorMainBlue};
  border-radius: 10px;
  img {
    width: 20px;
  }

  @media all and (max-width: 1023px) {
    flex: none;
    width: calc(33.333333% - 13.4px);
  }
  @media all and (max-width: 767px) {
    width: 100%;
    padding: 16px 12px;
  }
`;
const TextBar = styled(Text)`
  position: relative;
  padding-left: 10px;
  word-break: keep-all;

  &::before {
    content: "-";
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default MainHr;
