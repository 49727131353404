import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ColorBgGray,
  ColorDarkBlack,
  ColorDarkGray,
  ColorLightBlack,
  ColorLineGray,
  ColorMainBlue,
  ColorWhite,
} from "../../Utiles/Color";
import { Btn, Container, FlexBox, Text } from "../../Assets/css/common";
import "../../Assets/css/swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import img_process1 from "../../Assets/images/process_img01.jpg";
import img_process2 from "../../Assets/images/process_img02.jpg";
import img_process3 from "../../Assets/images/process_img03.jpg";
import img_process4 from "../../Assets/images/process_img04.jpg";
import img_process5 from "../../Assets/images/process_img05.jpg";
import img_process6 from "../../Assets/images/process_img06.jpg";
import ico_plus from "../../Assets/images/ico_plus02.png";
import { useNavigate } from "react-router-dom";

function MainProcess() {
  const navigate = useNavigate();
  const [pc, setPc] = useState(true);
  const [contSlider, setContSlider] = useState(null);

  const contParams = {
    onSwiper: setContSlider,
    spaceBetween: 10,
  };

  const btnSlideClick = (e, idx) => {
    const btns = document.querySelectorAll("#btn_slider > div > div");
    btns.forEach((e) => {
      if (e.classList) e.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    if (contSlider) {
      contSlider.slideTo(idx, 500);
    }
  };
  const contSlideChange = (swiper) => {
    const btns = document.querySelectorAll("#btn_slider > div > div");
    const btnWrap = document.querySelector("#btn_slider > div");
    const activeBtn = btnWrap.getElementsByTagName("div")[swiper.activeIndex];
    btns.forEach((e) => {
      if (e.classList) e.classList.remove("active");
    });
    activeBtn.classList.add("active");
  };

  useEffect(() => {
    const winW = window.innerWidth;
    winW > 767 ? setPc(true) : setPc(false);
  }, []);

  return (
    <>
      <Wrapper>
        <Container>
          <Text
            as="h3"
            color={ColorDarkBlack}
            weight={"700"}
            align={"center"}
            className="mb-7.5 text-1.5xl md:text-4xl lg:text-5xl break-keep"
          >
            Canon ERP의{" "}
            <span style={{ color: ColorMainBlue }}>
              빠르고, <br className="block md:hidden" /> 편리한 프로세스
            </span>
            를 경험해보세요.
          </Text>
          <BtnSlider id="btn_slider">
            <FlexBox>
              <div
                className="active"
                onMouseEnter={pc ? (e) => btnSlideClick(e, 0) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 0) : () => {}}
              >
                <BtnNum>1</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  ERP 가입
                </Text>
              </div>
              <div
                onMouseEnter={pc ? (e) => btnSlideClick(e, 1) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 1) : () => {}}
              >
                <BtnNum>2</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  관리자 승인
                </Text>
              </div>
              <div
                onMouseEnter={pc ? (e) => btnSlideClick(e, 2) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 2) : () => {}}
              >
                <BtnNum>3</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  프로그램 다운로드
                </Text>
              </div>
              <div
                onMouseEnter={pc ? (e) => btnSlideClick(e, 3) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 3) : () => {}}
              >
                <BtnNum>4</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  거래처 등록
                </Text>
              </div>
              <div
                onMouseEnter={pc ? (e) => btnSlideClick(e, 4) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 4) : () => {}}
              >
                <BtnNum>5</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  물품 등록
                </Text>
              </div>
              <div
                onMouseEnter={pc ? (e) => btnSlideClick(e, 5) : () => {}}
                onClick={!pc ? (e) => btnSlideClick(e, 5) : () => {}}
              >
                <BtnNum>6</BtnNum>
                <Text
                  size={"16px"}
                  color={ColorLightBlack}
                  weight={"700"}
                  className="hidden md:block mt-2.5"
                >
                  발주 신청
                </Text>
              </div>
            </FlexBox>
          </BtnSlider>
          <ContSlider>
            <Swiper {...contParams} onSlideChange={contSlideChange}>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process1} alt="ERP 가입" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      ERP 가입
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      저희 사이트에서 간단한 정보를 <br />
                      입력하고 빠르게 가입을 해주세요.
                    </Text>
                  </ContText>
                  <DetailBtn
                    size={"16px"}
                    color={ColorMainBlue}
                    weight={"700"}
                    width={"160px"}
                    padding={"10px 0"}
                    border={`2px solid ${ColorMainBlue}`}
                    radius={"24px"}
                    className="hidden md:flex"
                    onClick={() => navigate("/join")}
                  >
                    자세히 보기{" "}
                    <img src={ico_plus} className="w-5.5" alt="plus" />
                  </DetailBtn>
                </ContBox>
              </SwiperSlide>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process2} alt="관리자 승인" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      관리자 승인
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      지정된 관리자가 빠른 승인절차를 걸쳐 <br />
                      ERP 세팅을 도와 드립니다.
                    </Text>
                  </ContText>
                </ContBox>
              </SwiperSlide>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process3} alt="프로그램 다운로드" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      프로그램 다운로드
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      ERP 프로그램을 <br />
                      다운 받을 수 있습니다.
                    </Text>
                  </ContText>
                </ContBox>
              </SwiperSlide>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process4} alt="거래처 등록" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      거래처 등록
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      병원과 거래하시는 <br />
                      거래처를 등록해주세요.
                    </Text>
                  </ContText>
                </ContBox>
              </SwiperSlide>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process5} alt="물품 등록" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      물품 등록
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      엑셀로 일괄 등록도 가능하고 <br />
                      수기로 하나씩 등록도 가능합니다.
                    </Text>
                  </ContText>
                </ContBox>
              </SwiperSlide>
              <SwiperSlide>
                <ContBox>
                  <ContImg>
                    <img src={img_process6} alt="발주 신청" />
                  </ContImg>
                  <ContText>
                    <Text
                      color={ColorMainBlue}
                      weight={"700"}
                      className="text-sm md:text-3xl lg:text-4xl"
                    >
                      발주 신청
                    </Text>
                    <Text
                      color={ColorLightBlack}
                      className="mt-1 md:mt-5 text-xs md:text-sm lg:text-lg"
                    >
                      이제 준비가 끝났습니다. <br />
                      물품 신청을 시작하시면 됩니다.
                    </Text>
                  </ContText>
                </ContBox>
              </SwiperSlide>
            </Swiper>
          </ContSlider>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 30px 0;
  }
`;
const BtnNum = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 2px solid ${ColorMainBlue};
  border-radius: 50%;
  background-color: ${ColorWhite};
  font-size: 16px;
  color: ${ColorDarkGray};
  font-weight: 700;
  cursor: pointer;

  @media all and (max-width: 767px) {
    width: 24px;
    height: 24px;
    border-width: 1px;
    font-size: 12px;
    font-weight: 600;
  }
`;
const BtnSlider = styled.div`
  position: relative;
  width: 100%;
  max-width: 888px;
  margin: 0 auto 30px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 19px;
    left: 8.333333%;
    width: 83.333333%;
    height: 2px;
    background-color: ${ColorMainBlue};
  }

  & > div > div {
    width: 16.666666%;
  }
  .active {
    ${BtnNum} {
      color: ${ColorWhite};
      background-color: ${ColorMainBlue};
    }
    p {
      color: ${ColorDarkBlack};
    }
  }
  // .swiper-slide-thumb-active {
  //   ${BtnNum} {
  //     color: ${ColorWhite};
  //     background-color: ${ColorMainBlue};
  //   }
  //   p {
  //     color: ${ColorDarkBlack};
  //   }
  // }

  @media all and (max-width: 767px) {
    margin-bottom: 10px;

    &::before {
      top: 12px;
      height: 1px;
    }
  }
`;
const ContSlider = styled.div``;
const ContBox = styled.div`
  position: relative;
  display: flex;
  gap: 60px;
  padding: 20px 60px;
  border: 1px solid ${ColorLineGray};
  border-radius: 10px;
  background-color: ${ColorWhite};

  @media all and (max-width: 1023px) {
    gap: 20px;
    padding: 20px;
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
const ContImg = styled.div`
  flex: 1.41;

  @media all and (max-width: 767px) {
    padding: 10px;
    border: 1px solid ${ColorLineGray};
    border-radius: 10px;
    background-color: ${ColorWhite};

    img {
      margin: 0 auto;
    }
  }
`;
const ContText = styled.div`
  padding-top: 60px;
  flex: 1;

  @media all and (max-width: 1023px) {
    padding-top: 20px;
  }
  @media all and (max-width: 767px) {
    padding-top: 0;
    text-align: center;
  }
`;
const DetailBtn = styled(Btn)`
  position: absolute;
  bottom: 30px;
  right: 30px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0 4px 10px rgba(2, 103, 154, 0.15);
`;

export default MainProcess;
