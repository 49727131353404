import React from "react";
import styled from "styled-components";
import { Container } from "../../../Assets/css/common";
import { ColorBgGray } from "../../../Utiles/Color";
import Header from "../../../Components/Header";
import ContactConts from "./ContactConts";
import Footer from "../../../Components/Footer";

// 구독서비스 > 담당자 문의

function ServiceContact() {
  return (
    <>
      <Header title={"구독 서비스 담당자 문의"} />
      <Wrapper>
        <Container>
          <ContactConts />
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 60px 0 150px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

export default ServiceContact;
