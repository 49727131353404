import axios from "axios";

export const API = axios.create({
   //baseURL: 'https://localhost:8080',
  // baseURL: "http://imedix.iptime.org:8010",
  baseURL: "https://canonerp.com:8080",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
// export const APIAuth = axios.create({
//   // baseURL: 'http://localhost:3000',
//   // baseURL: "http://imedix.iptime.org:8010",
//   baseURL: "/",
//   timeout: 5000,
//   withCredentials: true,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//   },
// });

export const authorizationToken = (token) => {
  if (token) {
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common["Authorization"];
  }
};
