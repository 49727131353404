import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import styled from "styled-components";
import {
  ColorDarkBlack,
  ColorDarkGray,
  ColorGray,
  ColorLightBlack,
  ColorLightGray,
  ColorMainBlue,
  ColorWhite,
} from "../../../Utiles/Color";
import {
  Btn,
  Container700,
  EditorWrap,
  FlexBox,
  Text,
} from "../../../Assets/css/common";
import ico_download from "../../../Assets/images/ico_download03.png";
import ico_person from "../../../Assets/images/ico_blue_person.png";
import { API } from "../../../Utiles/API";
import { formatDotYmd, shortImgName, tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import Editor from "../../../Components/Editor";
import ConfirmModal from "../../../Components/ConfirmModal";
import Footer from "../../../Components/Footer";

// 고객센터 > 1:1 문의 > 상세

function InquiryView() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const tokenuser = tkInfo.tokenuser;
  const tokenrole = tkInfo.tokenrole;
  // const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const params = useParams();
  const paramsIdx = params?.idx;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [isCmt, setIsCmt] = useState(false);
  const [contValue, setContValue] = useState("");
  const [registModal, setRegistmModal] = useState(false);

  const cmtSubmit = () => {
    if (contValue) {
      setRegistmModal(true);
    }
  };

  //========== API
  const getInquiryItem = async () => {
    try {
      const res = await API.get(`/qna/${paramsIdx}`);
      const resData = res.data;

      if (resData) {
        if (resData.userid !== tokenuser && tokenrole !== "ROLE_ADMIN") {
          alert("본인 글이 아닙니다.");
          navigate(-1);
          return;
        }

        const date = formatDotYmd(resData.createdate);
        let filterData = { ...resData, createdate: date };
        if (resData?.filepath) {
          const filename = shortImgName(resData.filepath);
          const filelink = `https://canonerp.com:8080/download?filename=${resData.filepath}`;
          filterData = { ...filterData, filename, filelink };
        }
        if (resData?.answer.length !== 0) {
          const cmtDate = formatDotYmd(resData.answer[0].createdate);
          filterData = { ...filterData, answerdate: cmtDate };
          setIsCmt(true);
        } else {
          setIsCmt(false);
        }
        // console.log(resData, filterData);
        setData(filterData);
        setLoading(false);
      } else {
        alert("존재하지 않는 게시물입니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
      alert("존재하지 않는 게시물입니다.");
      navigate(-1);
    }
  };
  const postAnswerItem = async () => {
    try {
      const cont_clean = DOMPurify.sanitize(contValue);
      let data = {
        midx: paramsIdx,
        contents: cont_clean,
        filepath: null,
      };
      const formData = JSON.stringify(data);
      await API.post("/qna/answer", formData);
      navigate("/customer/inquiry");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/");
    } else {
      getInquiryItem();
    }
  }, []);

  return (
    <>
      <Header subTitle={"1:1 문의 상세"} />
      <Wrapper>
        <Container700>
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <>
              <Text
                as="h3"
                color={ColorDarkBlack}
                weight={"700"}
                align={"center"}
                className="text-lg md:text-3xl lg:text-4xl"
              >
                {data.title}
              </Text>
              <Text
                color={ColorLightBlack}
                align={"center"}
                className="mt-2.5 md:mt-5 text-xs md:text-sm"
              >
                {data.createdate}
              </Text>
              <EditorWrap className="ql-snow mt-5 md:mt-10">
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: data.contents }}
                ></div>
              </EditorWrap>
              {data.filepath && (
                <DownBtn
                  href={data.filelink}
                  download
                  className="mt-5 md:mt-10"
                >
                  <Text
                    color={ColorDarkGray}
                    weight={"700"}
                    className="ellipsis text-sm md:text-base"
                  >
                    {data.filename}
                  </Text>
                  <i>
                    <img src={ico_download} alt="download" />
                  </i>
                </DownBtn>
              )}
            </>
          )}
          {/*
            회원 계정, 답변 X -> 목록 버튼
            회원 계정, 답변 O -> 관리자 답변 글, 목록 버튼
            관리자 계정, 답변 X -> 관리자 답변 input, 등록 버튼
            관리자 계정, 답변 O -> 관리자 답변 글, 목록 버튼
          */}
          {tokenrole === "ROLE_ADMIN" || isCmt ? (
            <>
              <CmtWrap className="mt-15">
                <FlexBox align={"center"} gap={"4px"} className="mb-2.5">
                  <i>
                    <img
                      src={ico_person}
                      alt="icon"
                      style={{ width: "20px" }}
                    />
                  </i>
                  <Text
                    color={ColorDarkBlack}
                    weight={"600"}
                    className="text-sm md:text-lg"
                  >
                    관리자 답변
                  </Text>
                  {isCmt && (
                    <Text
                      color={ColorLightBlack}
                      className="ml-1.5 text-xs md:text-sm"
                    >
                      {data.answerdate}
                    </Text>
                  )}
                </FlexBox>
                {isCmt ? (
                  <EditorWrap className="ql-snow">
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data.answer[0]?.contents,
                      }}
                    ></div>
                  </EditorWrap>
                ) : (
                  <Editor value={contValue} setValue={setContValue} />
                  // <textarea placeholder="답변을 입력해주세요."></textarea>
                )}
              </CmtWrap>
              {!isCmt && (
                <Btn
                  width={"100%"}
                  height={"48px"}
                  radius={"6px"}
                  bgColor={ColorMainBlue}
                  size={"16px"}
                  color={ColorWhite}
                  weight={"700"}
                  className="mt-10"
                  onClick={cmtSubmit}
                >
                  등 록
                </Btn>
              )}
            </>
          ) : (
            ""
          )}
          {tokenrole !== "ROLE_ADMIN" || data?.answer?.length === 0 ? (
            <FlexBox justify={"flex-end"} className="mt-15">
              <Btn
                width={"80px"}
                height={"48px"}
                radius={"6px"}
                border={`1px solid ${ColorDarkGray}`}
                size={"14px"}
                color={ColorDarkGray}
                weight={"600"}
                onClick={() => navigate("/customer/inquiry")}
              >
                목 록
              </Btn>
            </FlexBox>
          ) : (
            ""
          )}
        </Container700>
      </Wrapper>
      <Footer />
      {registModal && (
        <ConfirmModal
          text={"답변을 등록하시겠습니까?"}
          onCancel={() => setRegistmModal(false)}
          onSubmit={postAnswerItem}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;
const DownBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: ${ColorLightGray};

  p {
    max-width: calc(100% - 29px);
  }
  img {
    width: 24px;
  }
`;
const CmtWrap = styled.div`
  padding: 20px 20px 15px;
  border: 1px solid ${ColorMainBlue};
  border-radius: 10px;

  textarea {
    width: 100%;
    height: 28px;
    padding: 0;
    border: 0;
    outline: none;
    resize: none;
    font-size: 16px;
    color: ${ColorLightBlack};

    &:placeholder {
      color: ${ColorGray};
    }
  }

  @media all and (max-width: 767px) {
    padding: 14px 12px 12px;
    textarea {
      font-size: 14px;
      height: 182px;
    }
  }
`;

export default InquiryView;
