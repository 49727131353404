import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ColorMainBlue, ColorWhite } from "../../Utiles/Color";
import { Text } from "../../Assets/css/common";
import { API } from "../../Utiles/API";
import HeaderPc from "./HeaderPc";
import HeaderM from "./HeaderM";
import HeaderQuick from "./HeaderQuick";

// HEADER

const hdMenu = [
  {
    name: "Canon ERP",
    link: "/erp/intro",
    dep2: [
      {
        name: "Canon ERP 소개ㅤㅤ",
        link: "/erp/intro",
      },
      {
        name: "소식",
        link: "/erp/news",
      },
    ],
  },
  {
    name: "구독 서비스ㅤㅤㅤ",
    link: "/service/price",
    dep2: [
      {
        name: "요금제 안내",
        link: "/service/price",
      },
      {
        name: "담당자 문의",
        link: "/service/contact",
      },
    ],
  },
  {
    name: "고객센터ㅤㅤㅤ",
    link: "/customer/inquiry",
    dep2: [
      {
        name: "1:1 문의",
        link: "/customer/inquiry",
      },
      {
        name: "사용설명서",
        link: "/customer/guide",
      },
    ],
  },
  {
    name: "다운로드",
    link: "/download/download",
    dep2: [
      {
        name: "다운로드",
        link: "/download/download",
      },
      {
        name: "업데이트 내역",
        link: "/download/update",
      },
    ],
  },
];

function Header(props) {
  const { title, subTitle } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);
  const [hdmSub, setHdmSub] = useState(false);
  const [isQuick, setIsQuick] = useState(false);
  // 공지X -> 0 / 공지O, subTitle 존재 -> 1 / 공지O, subTitle 존재X -> 2
  const [noticeType, setNoticeType] = useState(0);
  const [noticeData, setNoticeData] = useState([]);

  const autoLogin = () => {
    const token = localStorage.getItem("token");
    if (token) {
      sessionStorage.setItem("token", token);
      setIsLogin(true);
    }
  };
  const handleLogout = () => {
    // sessionStorage.clear();
    // localStorage.clear();
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    setIsLogin(false);
    navigate("/");
  };

  //========== API
  const getNoticeRecent = async () => {
    const res = await API.get("/notice");
    const resData = res.data.list;
    // console.log("resDataindex",resData)
    const filterData = resData?.find((item) => {
      if (item.gbdc !== "DC") return item;
    });
    setNoticeData(filterData);
  };

  useEffect(() => {
    getNoticeRecent();
  }, []);
  useEffect(() => {
    location.pathname === "/" || title ? setHdmSub(false) : setHdmSub(true);
    location.pathname !== "/service/contact"
      ? setIsQuick(true)
      : setIsQuick(false);

    if (!sessionStorage.getItem("token")) {
      autoLogin();
    } else {
      setIsLogin(true);
    }

    // 공지가 존재하면? 아래 코드 실행
    noticeData && subTitle ? setNoticeType(1) : setNoticeType(2);
  }, [location, title, subTitle]);

  return (
    <>
      <Wrapper>
        {noticeType !== 0 ? (
          <NoticeBanner className={noticeType === 1 ? "hidden md:block" : ""}>
            <button
              onClick={() => navigate(`/erp/news/view/${noticeData?.idx}`)}
            >
              <Text
                color={ColorWhite}
                weight={"700"}
                align={"center"}
                className="ellipsis text-sm md:text-base"
              >
                {noticeData?.title}
              </Text>
            </button>
          </NoticeBanner>
        ) : (
          ""
        )}
        <HeaderPc hdMenu={hdMenu} isLogin={isLogin} onLogout={handleLogout} />
        <HeaderM
          {...props}
          hdMenu={hdMenu}
          hdmSub={hdmSub}
          isLogin={isLogin}
          onLogout={handleLogout}
        />
      </Wrapper>
      {isQuick ? <HeaderQuick /> : ""}
      <HeaderBlank className={`type0${noticeType}`} />
    </>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;
const NoticeBanner = styled.div`
  background-color: ${ColorMainBlue};

  button {
    display: block;
    width: 100%;
    padding: 12px 0;
    p {
      width: 100%;
      max-width: 1200px;
      padding: 0 30px;
      margin: 0 auto;
    }
  }

  @media all and (max-width: 767px) {
    button {
      padding: 10px 0;
      p {
        padding: 0 16px;
      }
    }
  }
`;
const HeaderBlank = styled.div`
  width: 100%;
  height: 64px;
  &.type01,
  &.type02 {
    height: 112px;
  }

  @media all and (max-width: 767px) {
    height: 56px;

    &.type01 {
      height: 56px;
    }
    &.type02 {
      height: 96px;
    }
  }
`;

export default Header;
