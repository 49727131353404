import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import styled from "styled-components";
import { ColorBgGray, ColorGray, ColorMainBlue } from "../../../Utiles/Color";
import {
  Btn,
  Container700,
  FlexBox,
  LoadingFull,
  Text,
} from "../../../Assets/css/common";
import { API } from "../../../Utiles/API";
import { shortImgName, tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import TextInput from "../../../Components/Input/TextInput";
import FileInput from "../../../Components/Input/FileInput";
import Editor from "../../../Components/Editor";
import ConfirmModal from "../../../Components/ConfirmModal";
import Loading from "../../../Components/Loading";
import Footer from "../../../Components/Footer";

// 다운로드 > 업데이트 내역 > 등록, 수정

function UpdateWrite() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const tokenrole = tkInfo.tokenrole;
  // const tokenrole = "ROLE_ADMIN";
  const navigate = useNavigate();
  const params = useParams();
  const paramsIdx = params?.idx;
  // * 제목, 내용
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    title: "",
    files: "",
  });
  const [contValue, setContValue] = useState("");
  const [fileValue, setFileValue] = useState("");
  const [inputModal, setInputModal] = useState(false);
  const [registModal, setRegistmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };
  const handleFileChange = (id, val) => {
    setValues({ ...values, [id]: val });
  };
  const goUpdateList = () => navigate("/download/update");

  const onSubmit = (e) => {
    e.preventDefault();
    const { title } = values;

    if (!(title && contValue)) {
      setInputModal(true);
    } else {
      // if (regScript.test(contValue))
      //   return alert("잘못된 형식입니다. 다시 입력해주세요.");
      paramsIdx ? setEditModal(true) : setRegistmModal(true);
    }
  };

  //========== API
  const makeFormData = () => {
    const cont_clean = DOMPurify.sanitize(contValue);
    let data = {
      ...values,
      contents: cont_clean,
    };
    if (fileValue) data = { ...data, filepath: fileValue };
    const formData = JSON.stringify(data);
    return formData;
  };
  const postUpdateItem = async () => {
    const formData = makeFormData();
    await API.post("/updatehistory", formData);
    goUpdateList();
  };
  const putUpdateItem = async () => {
    const formData = makeFormData();
    await API.put(`/updatehistory/${paramsIdx}`, formData);
    goUpdateList();
  };
  const deleteUpdateItem = async () => {
    await API.delete(`/updatehistory/${paramsIdx}`);
    goUpdateList();
  };
  const getUpdateItem = async () => {
    setLoading(true);
    const res = await API.get(`/updatehistory/${paramsIdx}`);
    const resData = res.data;
    if (resData) {
      let valueData = {
        ...values,
        title: resData.title,
      };
      if (resData.filepath) {
        const imgFilePath = resData.filepath;
        const imgName = shortImgName(imgFilePath);
        setFileValue(imgFilePath);
        valueData = { ...valueData, files: imgName };
      }
      setValues(valueData);
      setContValue(resData.contents);
      setLoading(false);
    } else {
      alert("존재하지 않는 게시물입니다.");
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      if (tokenrole !== "ROLE_ADMIN") {
        alert("권한이 없습니다.");
        navigate("/");
      } else {
        if (paramsIdx) {
          getUpdateItem();
        }
      }
    }
  }, []);

  return (
    <>
      <Header subTitle={"업데이트 내역"} />
      {loading ? (
        <LoadingFull>
          <Loading />
        </LoadingFull>
      ) : (
        ""
      )}
      <Wrapper>
        <Container700>
          <Text
            as="h3"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block mb-7.5"
          >
            업데이트 내역
          </Text>
          <form onSubmit={onSubmit}>
            <FlexBox direction={"column"} gap={"20px"}>
              <TextInput
                type={"text"}
                phText={"제목"}
                id={"title"}
                value={values.title}
                onChange={handleChange}
              />
              <Editor value={contValue} setValue={setContValue} />
              <FileInput
                phText={"파일 업로드"}
                id={"files"}
                value={values.files}
                onChange={handleFileChange}
                setFile={setFileValue}
              />
            </FlexBox>
            {paramsIdx ? (
              <>
                {/* 관리자 수정 시 */}
                <FlexBox gap={"10px"} className="mt-10">
                  <Btn
                    type="button"
                    size={"16px"}
                    color={"#fff"}
                    weight={"700"}
                    width={"100%"}
                    padding={"12px 0"}
                    radius={"6px"}
                    bgColor={ColorGray}
                    onClick={() => setDeleteModal(true)}
                  >
                    삭 제
                  </Btn>
                  <Btn
                    type="submit"
                    size={"16px"}
                    color={"#fff"}
                    weight={"700"}
                    width={"100%"}
                    padding={"12px 0"}
                    radius={"6px"}
                    bgColor={ColorMainBlue}
                  >
                    수 정
                  </Btn>
                </FlexBox>
              </>
            ) : (
              <>
                {/* 회원 등록 시 */}
                <Btn
                  type="submit"
                  size={"16px"}
                  color={"#fff"}
                  weight={"700"}
                  width={"100%"}
                  padding={"12px 0"}
                  radius={"6px"}
                  bgColor={ColorMainBlue}
                  className="mt-10"
                >
                  등 록
                </Btn>
              </>
            )}
          </form>
        </Container700>
      </Wrapper>
      <Footer />
      {inputModal && (
        <ConfirmModal
          text={
            <>
              필수 입력 항목<span style={{ color: ColorMainBlue }}>*</span>을
              입력해주세요.
            </>
          }
          onSubmit={() => setInputModal(false)}
        />
      )}
      {registModal && (
        <ConfirmModal
          text={"업데이트 내역을 등록하시겠습니까?"}
          onCancel={() => setRegistmModal(false)}
          onSubmit={postUpdateItem}
        />
      )}
      {editModal && (
        <ConfirmModal
          text={"업데이트 내역을 수정하시겠습니까?"}
          onCancel={() => setEditModal(false)}
          onSubmit={putUpdateItem}
        />
      )}
      {deleteModal && (
        <ConfirmModal
          text={"업데이트 내역을 삭제하시겠습니까?"}
          onCancel={() => setDeleteModal(false)}
          onSubmit={deleteUpdateItem}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 60px 0 150px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;

export default UpdateWrite;
