import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ColorDarkBlack,
  ColorDarkGray,
  ColorLightBlack,
  ColorLightGray,
} from "../../../Utiles/Color";
import {
  Btn,
  Container700,
  EditorWrap,
  FlexBox,
  Text,
} from "../../../Assets/css/common";
import ico_download from "../../../Assets/images/ico_download03.png";
import { API } from "../../../Utiles/API";
import { formatDotYmd, shortImgName, tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import Footer from "../../../Components/Footer";

// 다운로드 > 업데이트 내역 > 상세

function UpdateView() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const navigate = useNavigate();
  const params = useParams();
  const paramsIdx = params?.idx;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  //========== API
  const getUpdateItem = async () => {
    const res = await API.get(`/updatehistory/${paramsIdx}`);
    const resData = res.data;

    if (resData) {
      const date = formatDotYmd(resData.createdate);
      let filterData = {
        ...resData,
        createdate: date,
      };
      if (resData.filepath) {
        const filename = shortImgName(resData.filepath);
        const filelink = `https://canonerp.com:8080/download?filename=${resData.filepath}`;
        filterData = { ...filterData, filename, filelink };
      }
      // console.log(resData, filterData);
      setData(filterData);
      setLoading(false);
    } else {
      alert("존재하지 않는 게시물입니다.");
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      getUpdateItem();
    }
  }, []);

  return (
    <>
      <Header subTitle={"업데이트 내역 상세"} />
      <Wrapper>
        <Container700>
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <>
              <Text
                as="h3"
                color={ColorDarkBlack}
                weight={"700"}
                align={"center"}
                className="text-lg md:text-3xl lg:text-4xl break-keep"
              >
                {data.title}
              </Text>
              <Text
                color={ColorLightBlack}
                align={"center"}
                className="mt-2.5 md:mt-5 text-xs md:text-sm"
              >
                {data.date}
              </Text>
              <EditorWrap className="ql-snow mt-5 md:mt-10">
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: data.contents }}
                ></div>
              </EditorWrap>
              {data.filepath && (
                <DownBtn
                  href={data.filelink}
                  download
                  className="mt-5 md:mt-10"
                >
                  <Text
                    color={ColorDarkGray}
                    weight={"700"}
                    className="text-sm md:text-base"
                  >
                    {data.filename}
                  </Text>
                  <i>
                    <img src={ico_download} alt="download" />
                  </i>
                </DownBtn>
              )}
            </>
          )}
          <FlexBox justify={"flex-end"} className="mt-12.5 md:mt-15">
            <Btn
              size={"14px"}
              color={ColorDarkGray}
              weight={"600"}
              width={"80px"}
              height={"48px"}
              border={`1px solid ${ColorDarkGray}`}
              radius={"6px"}
              onClick={() => navigate("/download/update")}
            >
              목 록
            </Btn>
          </FlexBox>
        </Container700>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 60px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 50px;
  }
`;
const DownBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 48px;
  border-radius: 6px;
  background-color: ${ColorLightGray};

  img {
    width: 24px;
  }
`;

export default UpdateView;
