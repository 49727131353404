import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../../../Assets/css/common";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import TabBtns from "../../../Components/TabBtns";
import NewsJoin from "./NewsJoin";
import NewsNotice from "./NewsNotice";

function ErpNews() {
  const [tabBtn, setTabBtn] = useState([]);
  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    setTabBtn([
      { name: "공지사항", func: () => setTabIdx(0) },
      { name: "가입현황", func: () => setTabIdx(1) },
    ]);
  }, []);

  return (
    <>
      <Header title={"Cannon ERP 소식"} />
      <Wrapper>
        <Container>
          <TabBtns btnInfo={tabBtn} />
          <div className="pt-5 md:pt-10">
            {tabIdx === 0 ? <NewsNotice /> : ""}
            {tabIdx === 1 ? <NewsJoin /> : ""}
          </div>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  padding: 40px 0 150px;

  @media all and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

export default ErpNews;
