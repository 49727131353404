import React from "react";
import ReactDOM from "react-dom/client";
import "tailwindcss/tailwind.css";
import "./Assets/css/common.css";
import App from "./App";
import { authorizationToken } from "./Utiles/API";

authorizationToken(sessionStorage.getItem("token"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    {/* </React.StrictMode> */}
    <App />
  </>
);
