import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FlexBox, Text, Btn } from "../../Assets/css/common";
import { ColorMainBlue, ColorBgGray, ColorDarkBlack } from "../../Utiles/Color";
import ico_person from "../../Assets/images/ico_person.png";
import ico_lock from "../../Assets/images/ico_lock.png";
import ico_building from "../../Assets/images/ico_building.png";
import ico_phone from "../../Assets/images/ico_phone.png";
import ico_mail from "../../Assets/images/ico_mail.png";
import ico_location from "../../Assets/images/ico_location.png";
import ico_document from "../../Assets/images/ico_document.png";
import ico_back from "../../Assets/images/ico_back_btn.png";
import { regEmail, regPasswd, regPhone } from "../../Utiles/Reg";
import { autoHypenPhone, shortImgName, tokenInfo } from "../../Utiles/common";
import TextInput from "../../Components/Input/TextInput";
import FileInput from "../../Components/Input/FileInput";
import { API } from "../../Utiles/API";
import ConfirmModal from "../../Components/ConfirmModal";

// 마이페이지 (회원정보수정)

function MypageEdit() {
  const navigate = useNavigate();
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const tokenid = tkInfo.tokenid;
  const tokenuser = tkInfo.tokenuser;
  const tokenhsp = tkInfo.tokenhsp;
  // * 비밀번호, 담당자명, 전화번호, 사업자등록증
  const editItems = {
    id: "",
    userid: "",
    oripasswd: "",
    passwd: "",
    passwd2: "",
    corpname: "",
    username: "",
    phone: "",
    email: "",
    address: "",
    corpfile: "",
  };
  const [values, setValues] = useState(editItems);
  const {
    oripasswd,
    passwd,
    passwd2,
    username,
    phone,
    email,
    address,
    corpfile,
  } = values;
  const [fileValue, setFileValue] = useState(editItems);
  const [msgs, setMsgs] = useState(editItems);
  const [modal, setModal] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleChange = (e) => {
    let val = e.target.value;
    if (e.target.id === "phone") {
      val = autoHypenPhone(val);
    }
    setValues({ ...values, [e.target.id]: val });
    msgReset();
  };
  const handleFileChange = (id, val) => {
    setValues({ ...values, [id]: val });
    msgReset();
  };
  const msgReset = () => setMsgs(editItems);
  const goBack = () => navigate(-1);

  const onPwCheck = (e) => {
    e.preventDefault();

    if (!oripasswd)
      return setMsgs({
        ...msgs,
        oripasswd: "현재 비밀번호를 입력해주세요.",
      });
    postPwCheck();
  };
  const onEdit = (e) => {
    e.preventDefault();
    inputCheck();
  };
  const inputCheck = () => {
    if ((passwd || passwd2))
      if (!regPasswd.test(passwd))
        return setMsgs({
          ...msgs,
          passwd: "비밀번호는 특수문자 포함 7자리 이상 입력해주세요.",
        });
      if(passwd !== passwd2)
        return setMsgs({
          ...msgs,
          passwd2: "새 비밀번호가 일치하지 않습니다.",
        });
    if (!username)
      return setMsgs({
        ...msgs,
        username: "담당자명을 입력해주세요.",
      });
    if (!phone)
      return setMsgs({
        ...msgs,
        phone: "전화번호를 입력해주세요.",
      });
    if (!regPhone.test(phone))
      return setMsgs({
        ...msgs,
        phone: "올바른 전화번호 형식이 아닙니다.",
      });
    if (email && !regEmail.test(email))
      return setMsgs({
        ...msgs,
        email: "올바른 이메일 형식이 아닙니다.",
      });
    if (!corpfile)
      return setMsgs({
        ...msgs,
        corpfile: "사업자등록증이 확인되어야 계정이 생성됩니다.",
      });
    msgReset();
    setModal(true);
  };
  const modalSubmit = () => {
    postEdit();
  };

  //========== API
  const postPwCheck = async () => {
    const formData = JSON.stringify({
      userid: tokenuser,
      passwd: oripasswd,
    });
    const res = await API.post("/auth/v1/pwcheck", formData);
    const resData = res.data;
    console.log(resData);
    if (resData.code === "-3") {
      setMsgs({
        ...msgs,
        oripasswd: "로그인 정보에 오류가 있습니다.",
      });
    } else if (resData.code === "200") {
      document.getElementById("oripasswd").disabled = true;
      await getInfo();
      setShowInfo(true);
    }
  };
  const getInfo = async () => {
    const formData = JSON.stringify({
      userid: tokenuser,
      passwd: oripasswd,
    });
    const res = await API.post("/auth/v1/login2", formData);
    const resData = res.data;
    // console.log(resData);
    if (resData) {
      let valueData = {
        ...values,
        username: resData.userName,
        phone: resData.tel,
      };
      if (resData.corpfile) {
        const imgFilePath = resData.corpfile;
        const imgName = shortImgName(imgFilePath);
        setFileValue(imgFilePath);
        valueData = { ...valueData, corpfile: imgName };
      }
      if (resData.email) valueData = { ...valueData, email: resData.email };
      if (resData.address)
        valueData = { ...valueData, address: resData.address };
      setValues(valueData);
    }
  };
  const postEdit = async () => {
    try {
      let data = { ...values };
      delete data.oripasswd;
      delete data.passwd2;
      if (!passwd) delete data.passwd;
      if (!address) data = { ...data, address: null };
      if (!email) data = { ...data, email: null };
      if (fileValue) data = { ...data, corpfile: fileValue };
      const formData = JSON.stringify(data);
      await API.post("/memberupdate", formData);

      alert("SUCCESS : 내정보 수정 성공!");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    } else {
      setValues({
        ...values,
        id: tokenid,
        userid: tokenuser,
        corpname: tokenhsp,
      });
    }
  }, []);

  return (
    <>
      <HeaderSub className="flex md:hidden">
        <BackBtn onClick={goBack}>
          <img src={ico_back} alt="이전 페이지" />
        </BackBtn>
        <Text size={"16px"} color={ColorDarkBlack} weight={"700"}>
          회원정보
        </Text>
      </HeaderSub>
      <Wrapper>
        <MypageWrap>
          <Text
            as="h2"
            size={"36px"}
            color={ColorMainBlue}
            weight={"700"}
            align={"center"}
            className="hidden md:block"
          >
            회원정보
          </Text>
          <form onSubmit={onPwCheck}>
            <FlexBox
              direction={"column"}
              gap={"20px"}
              className="mt-0 md:mt-7.5"
            >
              <TextInput
                type={"text"}
                phText={"아이디"}
                icon={ico_person}
                id={"userid"}
                value={values.userid}
                disabled={true}
              />
              <TextInput
                type={"password"}
                phText={"현재 비밀번호"}
                icon={ico_lock}
                id={"oripasswd"}
                value={values.oripasswd}
                onChange={handleChange}
                validMsg={msgs.oripasswd}
              />
            </FlexBox>
            {!showInfo ? (
              <Btn
                type="submit"
                size={"16px"}
                color={"#fff"}
                weight={"700"}
                width={"100%"}
                padding={"12px 0"}
                radius={"6px"}
                bgColor={ColorMainBlue}
                className="mt-10"
              >
                확 인
              </Btn>
            ) : (
              ""
            )}
          </form>

          {showInfo ? (
            <form onSubmit={onEdit}>
              <FlexBox direction={"column"} gap={"20px"} className="mt-5">
                <TextInput
                  type={"password"}
                  phText={"새 비밀번호"}
                  icon={ico_lock}
                  id={"passwd"}
                  value={values.passwd}
                  onChange={handleChange}
                  validMsg={msgs.passwd}
                />
                <TextInput
                  type={"password"}
                  phText={"새 비밀번호 확인"}
                  icon={ico_lock}
                  id={"passwd2"}
                  value={values.passwd2}
                  onChange={handleChange}
                  validMsg={msgs.passwd2}
                />
                <TextInput
                  type={"text"}
                  phText={"병원명"}
                  icon={ico_building}
                  id={"corpname"}
                  value={values.corpname}
                  disabled={true}
                />
                <TextInput
                  type={"text"}
                  phText={"담당자명"}
                  icon={ico_person}
                  id={"username"}
                  value={values.username}
                  onChange={handleChange}
                  validMsg={msgs.username}
                />
                <TextInput
                  type={"text"}
                  phText={"전화번호"}
                  icon={ico_phone}
                  id={"phone"}
                  value={values.phone}
                  onChange={handleChange}
                  validMsg={msgs.phone}
                />
                <TextInput
                  type={"text"}
                  phText={"이메일"}
                  icon={ico_mail}
                  id={"email"}
                  value={values.email}
                  onChange={handleChange}
                  validMsg={msgs.email}
                />
                <TextInput
                  type={"text"}
                  phText={"주소"}
                  icon={ico_location}
                  id={"addr"}
                  value={values.addr}
                  onChange={handleChange}
                />
                <FileInput
                  phText={"사업자 등록증"}
                  icon={ico_document}
                  id={"corpfile"}
                  value={values.corpfile}
                  onChange={handleFileChange}
                  setFile={setFileValue}
                  validMsg={msgs.corpfile}
                />
              </FlexBox>
              <Btn
                type="submit"
                size={"16px"}
                color={"#fff"}
                weight={"700"}
                width={"100%"}
                padding={"12px 0"}
                radius={"6px"}
                bgColor={ColorMainBlue}
                className="mt-10"
              >
                수 정
              </Btn>
            </form>
          ) : (
            ""
          )}
        </MypageWrap>
      </Wrapper>
      {modal && (
        <ConfirmModal
          text={"정보 수정을 완료하시겠습니까?"}
          onSubmit={modalSubmit}
          onCancel={() => setModal(false)}
        />
      )}
    </>
  );
}

const HeaderSub = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 8px;
  background-color: ${ColorBgGray};
  z-index: 5;
`;
const BackBtn = styled.button`
  position: absolute;
  top: calc(50% - 20px);
  left: 8px;
  padding: 4px;
  img {
    width: 32px;
  }
`;
const Wrapper = styled.div`
  min-height: 100vh;
  padding: 160px 0 100px;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 76px 0 60px;
  }
`;
const MypageWrap = styled.div`
  width: 100%;
  max-width: 412px;
  padding: 0 16px;
  margin: 0 auto;
`;

export default MypageEdit;
