import React, { useState } from "react";
import styled from "styled-components";
import { ColorMainBlue, ColorWhite } from "../../Utiles/Color";
import { Btn } from "../../Assets/css/common";
import ico_contact from "../../Assets/images/ico_contact.png";
import ContactConts from "../../Pages/Service/Contact/ContactConts";
import ico_close from "../../Assets/images/ico_close_btn.png";

function HeaderQuick() {
  const [quickModal, setQuickModal] = useState(false);

  return (
    <>
      <QuickBtn onClick={() => setQuickModal(true)}>
        <img
          src={ico_contact}
          alt="icon"
          className="hidden md:block"
          style={{ width: "24px" }}
        />
        빠른 <br />
        문의하기
      </QuickBtn>
      {quickModal ? (
        <QuickModalBg>
          <QuickModalWrap>
            <ClostBtn onClick={() => setQuickModal(false)}>
              <img src={ico_close} alt="close modal" />
            </ClostBtn>
            <ContactConts />
          </QuickModalWrap>
        </QuickModalBg>
      ) : (
        ""
      )}
    </>
  );
}

const QuickBtn = styled(Btn)`
  position: fixed;
  top: 152px;
  right: 0;
  width: 74px;
  padding: 13px 0;
  border-radius: 16px 0 0 16px;
  background-color: ${ColorMainBlue};
  font-size: 12px;
  color: ${ColorWhite};
  font-weight: 600;
  text-align: center;
  z-index: 1000;

  img {
    margin: 0 auto 2px;
  }

  @media all and (max-width: 767px) {
    width: 60px;
    top: 116px;
  }
`;
const QuickModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
`;
const QuickModalWrap = styled.div`
  position: relative;
  display: block;
  width: 96%;
  max-width: 400px;
  max-height: 90vh;
  padding: 30px;
  border-radius: 10px;
  background-color: ${ColorWhite};
  overflow-y: auto;

  @media all and (max-width: 767px) {
    padding: 30px 16px;
    form > div {
      gap: 10px;
    }
  }
`;
const ClostBtn = styled(Btn)`
  position: absolute;
  top: 15px;
  right: 15px;

  img {
    width: 24px;
  }

  @media all and (max-width: 767px) {
    top: 4px;
    right: 4px;
    padding: 8px;
  }
`;

export default HeaderQuick;
