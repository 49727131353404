import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ColorBgGray,
  ColorDarkBlack,
  ColorLightBlack,
  ColorMainBlue,
  ColorRed,
  ColorWhite,
} from "../../../Utiles/Color";
import { Btn, Container, Text } from "../../../Assets/css/common";
import ico_arrow from "../../../Assets/images/ico_download.png";
import { tokenInfo } from "../../../Utiles/common";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";

function DownDownload() {
  const tkInfo = tokenInfo();
  const token = tkInfo.token;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요합니다.");
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <>
      <Header title={"다운로드"} />
      <Wrapper>
        <Container>
          <Text
            color={ColorDarkBlack}
            weight={"700"}
            align={"center"}
            className="text-lg md:text-3xl lg:text-4xl leading-tight"
          >
            이미 설치되신 분들은 <br />
            다시 설치할 필요가 없습니다.
          </Text>
          <Text
            color={ColorLightBlack}
            align={"center"}
            className="mt-2.5 md:mt-5 text-xs md:text-base"
          >
            자동 업데이트로 항상 최신버전을 유지합니다.
          </Text>
          <Text
            color={ColorRed}
            align={"center"}
            className="mt-2.5 md:mt-3 text-xs md:text-base"
          >
            윈도우의 배율 및 레이아웃 설정이 100%일때 최적화 되어있습니다.
          </Text>          
          <DownBtn
            as="a"
            color={ColorWhite}
            weight={"700"}
            border={`6px solid ${ColorWhite}`}
            radius={"64px"}
            bgColor={ColorMainBlue}
            className="mx-auto mt-15 md:mt-25 text-base md:text-4xl"
            href="https://canonerp.com:8080/download?filename=SAONProSetup.exe"
          >
            <span>ERP 프로그램</span>
            <i>
              <img src={ico_arrow} alt="download" />
            </i>
          </DownBtn>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 160px 0;
  background-color: ${ColorBgGray};

  @media all and (max-width: 767px) {
    padding: 80px 0;
  }
`;
const DownBtn = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 360px;
  height: 96px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

  img {
    width: 40px;
  }

  @media all and (max-width: 767px) {
    width: 230px;
    height: 62px;

    img {
      width: 24px;
    }
  }
`;

export default DownDownload;
