import React, { useState } from "react";
import styled from "styled-components";
import { Btn, Container, FlexBox, Text } from "../../Assets/css/common";
import {
  ColorBlack,
  ColorLightBlack,
  ColorLightGray,
} from "../../Utiles/Color";
import AgreeModal from "../AgreeModal";

function Footer() {
  const [ftAgreeModal, setFtAgreeModal] = useState(false);

  return (
    <>
      <Wrapper>
        <Container>
          <FlexBox className="gap-4 md:gap-12 justify-between md:justify-normal">
            <Text
              color={ColorBlack}
              weight={"700"}
              className="text-sm md:text-base"
            >
              캐논코리아 주식회사
            </Text>
            <Btn
              color={ColorBlack}
              weight={"600"}
              onClick={() => setFtAgreeModal(true)}
              className="text-xs md:text-sm"
            >
              이용약관
            </Btn>
          </FlexBox>
          <div className="mt-3">
            <Text
              color={ColorLightBlack}
              className="text-xs md:text-sm break-keep"
            >
              대표자 : 박정우 | 사업자등록번호 : 120-81-15636 |{" "}
              <br className="block md:hidden" />
              주소 : 서울특별시 강남구 테헤란로 607 (삼성동) |{" "}
              <br className="block md:hidden" />
              문의하기 : bridge@saon.co.kr
            </Text>
            <Text color={ColorLightBlack} className="text-xs md:text-sm">
              Copyright CANON KOREA INC. All rights reserved.
            </Text>
            <Text color={ColorLightBlack} className="text-xs md:text-sm">
              본 사이트의 콘텐츠는 저작권법의 보호를 받는바, 무단 전재, 복사,
              배포 등 을 금합니다.
            </Text>
          </div>
        </Container>
      </Wrapper>
      {ftAgreeModal && <AgreeModal onSubmit={() => setFtAgreeModal(false)} />}
    </>
  );
}

const Wrapper = styled.div`
  padding: 40px 0;
  background-color: ${ColorLightGray};
`;

export default Footer;
