import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ColorMainBlue,
  ColorWhite,
  ColorBgGray,
  ColorBlack,
  ColorDarkBlack,
} from "../../Utiles/Color";
import { Btn, Text } from "../../Assets/css/common";
import img_logo from "../../Assets/images/logo_m.png";
import ico_home from "../../Assets/images/ico_home_btn.png";
import ico_menu from "../../Assets/images/ico_menu_btn.png";
import ico_back from "../../Assets/images/ico_back_btn.png";
import ico_close from "../../Assets/images/ico_close_btn.png";
import ico_arrow from "../../Assets/images/arr01.png";

// HEADER - Mobile

function HeaderM(props) {
  const {
    hdMenu,
    hdmSub,
    title,
    subTitle,
    isLogin,
    onLogout,
    hdmBackClick = () => navigate(-1),
  } = props;
  const navigate = useNavigate();
  const [hdmMenu, setHdmMenu] = useState([]);
  const [hdMShow, setHdMShow] = useState(false);

  const onMenuShow = () => setHdMShow(true);
  const onMenuHide = () => setHdMShow(false);

  useEffect(() => {
    const arr = hdMenu.flatMap((e) => e.dep2 || []);
    setHdmMenu(arr);
  }, [hdMenu]);

  return (
    <>
      {!hdmSub ? (
        <HeaderWrap className="flex md:hidden">
          <Link to={"/"}>
            <IconM src={ico_home} alt="" />
          </Link>
          {title ? (
            <Text size={"16px"} color={ColorDarkBlack} weight={"700"}>
              {title}
            </Text>
          ) : (
            <Logo src={img_logo} alt="Medical ERP With CanonKorea Logo" />
          )}
          <MenuBtn onClick={onMenuShow}>
            <IconM src={ico_menu} />
          </MenuBtn>
        </HeaderWrap>
      ) : (
        <HeaderSubWrap className="flex md:hidden">
          <Btn onClick={hdmBackClick}>
            <img src={ico_back} alt="이전 페이지" />
          </Btn>
          <Text size={"16px"} color={ColorDarkBlack} weight={"700"}>
            {subTitle}
          </Text>
        </HeaderSubWrap>
      )}
      {/* Mobile Menu */}
      <MenuWrap className={hdMShow ? "active" : ""}>
        <MenuTop>
          <Text size={"16px"} color={ColorDarkBlack} weight={"700"}>
            메뉴
          </Text>
          <CloseBtn onClick={onMenuHide}>
            <IconM src={ico_close} />
          </CloseBtn>
        </MenuTop>
        <MenuConts>
          {hdmMenu?.map((e, i) => (
            <Text
              key={i}
              as="div"
              size={"14px"}
              color={ColorBlack}
              weight={"600"}
            >
              <Link to={e.link}>
                {e.name}
                <i>
                  <img src={ico_arrow} alt="arrow" />
                </i>
              </Link>
            </Text>
          ))}
        </MenuConts>
        <MenuBottom>
          {isLogin ? (
            <>
              {/* 로그인 시 */}
              <Text as="div" size={"14px"}>
                <button onClick={onLogout}>로그아웃</button>
              </Text>
              <Text as="div" size={"14px"}>
                <Link to={"/mypage"}>마이페이지</Link>
              </Text>
            </>
          ) : (
            <>
              {/* 비로그인 시 */}
              <Text as="div" size={"14px"}>
                <Link to={"/join"}>회원가입</Link>
              </Text>
              <Text as="div" size={"14px"}>
                <Link to={"/login"}>로그인</Link>
              </Text>
            </>
          )}
        </MenuBottom>
      </MenuWrap>
    </>
  );
}

const HeaderWrap = styled.div`
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: ${ColorBgGray};
  a,
  button {
    padding: 8px;
  }
`;
const HeaderSubWrap = styled(HeaderWrap)`
  position: relative;
  height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  background-color: ${ColorBgGray};
  a,
  button {
    position: absolute;
    top: calc(50% - 20px);
    left: 8px;
    padding: 4px;

    img {
      width: 32px;
    }
  }
`;
const MenuBtn = styled.button``;
const Logo = styled.img`
  width: 112px;
`;
const IconM = styled.img`
  width: 24px;
`;

const MenuWrap = styled.div`
  position: fixed;
  top: 0;
  right: -120%;
  width: 100%;
  min-height: 100vh;
  background-color: ${ColorBgGray};
  overflow-y: auto;
  transition: right 0.5s;

  &.active {
    right: 0;
  }
`;
const MenuTop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;
const CloseBtn = styled.button`
  position: absolute;
  top: calc(50% - 20px);
  right: 8px;
  padding: 8px;
`;
const MenuConts = styled.div`
  a {
    position: relative;
    display: block;
    padding: 14px 16px 13px;

    i {
      position: absolute;
      top: calc(50% - 10px);
      right: 16px;

      img {
        width: 20px;
      }
    }
  }
`;
const MenuBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 16px;
  margin-top: 40px;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
  }
  div:first-of-type a,
  div:first-of-type button {
    color: ${ColorMainBlue};
    border: 1px solid ${ColorMainBlue};
    background-color: ${ColorWhite};
  }
  div:last-of-type a,
  div:last-of-type button {
    color: ${ColorWhite};
    background-color: ${ColorMainBlue};
  }
`;

export default HeaderM;
