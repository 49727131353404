import React from "react";
import styled from "styled-components";
import { ColorDarkBlack } from "../../../Utiles/Color";
import { Text } from "../../../Assets/css/common";
import img_intro8 from "../../../Assets/images/intro_img08.jpg";
import img_intro9 from "../../../Assets/images/intro_img09.jpg";

function IntroStatistics() {
  return (
    <>
      <div>
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          다양한 통계 제공
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro8} alt="통계 이미지" />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          우리 병원에서 사용하는 <br />
          모든 물품을 한꺼번에!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro9} alt="물품 리스트 이미지" />
        </IntroImg>
      </div>
    </>
  );
}

const IntroImg = styled.div`
  img {
    margin: 0 auto;
  }
`;

export default IntroStatistics;
