import React from "react";
import styled from "styled-components";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ColorLightBlack,
  ColorDarkBlack,
  ColorMainBlue,
  ColorBlack,
  ColorLineGray,
} from "../../Utiles/Color";
import { Container, FlexBox, Text } from "../../Assets/css/common";
import "../../Assets/css/swiper";
import img_doctor1 from "../../Assets/images/hsp01.jpg";
import img_doctor2 from "../../Assets/images/hsp02.jpg";
import img_doctor3 from "../../Assets/images/hsp04.jpg";
import img_doctor4 from "../../Assets/images/hsp03.jpg";

function MainAsk() {
  const sliderParams = {
    modules: [Autoplay],
    spaceBetween: 12,
    slidesPerView: 1.5,
    //autoplay: { delay: 2000, disableOnInteraction: false },
    breakpoints: {
      768: { spaceBetween: 12, slidesPerView: 2 },
      1024: { spaceBetween: 30, slidesPerView: 3.8 },
    },
    style: { overflow: "visible" },
  };

  return (
    <>
      <Wrapper>
        <SlideContainer>
          <Container>
            <Text
              as="h3"
              color={ColorDarkBlack}
              weight={"700"}
              align={"center"}
              className="mb-7.5 text-1.5xl md:text-4xl lg:text-5xl break-keep"
            >
              고객에게 물었습니다. <br />
              <span style={{ color: ColorMainBlue }}>
                왜 Canon ERP를 선택했나요?
              </span>
            </Text>
            <Swiper {...sliderParams}>
              <SwiperSlide>
                <SlideConts>
                  <SlideImg>
                    <img src={img_doctor1} alt="의사 이미지" />
                  </SlideImg>
                  <SlideText>
                    <Text
                      color={ColorLightBlack}
                      className="ellipsis text-xs md:text-base"
                    >
                      수시로 변동되는 병원의 품목 마스터를 엑셀로 관리하다보니 이력관리도 안되고 실수가 잦아져 항상 공급업체의 지원자료를 받아야 했습니다.
Canon ERP는 이러한 부분들을 효율적으로 병원이 주도적으로 구매관리 할 수 있게 지원해줘서 편리합니다.

                    </Text>
                    <FlexBox
                      align={"flex-end"}
                      direction={"column"}
                      className="mt-7 md:mt-14"
                    >
                      <Text
                        color={ColorBlack}
                        weight={"600"}
                        className="text-xs md:text-sm"
                      >
                        00병원
                      </Text>
                      <Text
                        color={ColorBlack}
                        weight={"700"}
                        className="text-sm md:text-base"
                      >
                        구매팀장
                      </Text>
                    </FlexBox>
                  </SlideText>
                </SlideConts>
              </SwiperSlide>
              <SwiperSlide>
                <SlideConts>
                  <SlideImg>
                    <img src={img_doctor2} alt="의사 이미지" />
                  </SlideImg>
                  <SlideText>
                    <Text
                      color={ColorLightBlack}
                      className="ellipsis text-xs md:text-base"
                    >
                      기존에 사용하던 범용 ERP의 경우 병원의 맞춤 시스템이 아니기 때문에 사용하는데 있어 한계점이 항상 있었습니다.
Canon ERP의 병원에 맞춤형 기능들을 통해 이러한 사용 한계점을 개선할 수 있었습니다.

                    </Text>
                    <FlexBox
                      align={"flex-end"}
                      direction={"column"}
                      className="mt-7 md:mt-14"
                    >
                      <Text
                        color={ColorBlack}
                        weight={"600"}
                        className="text-xs md:text-sm"
                      >
                        00병원
                      </Text>
                      <Text
                        color={ColorBlack}
                        weight={"700"}
                        className="text-sm md:text-base"
                      >
                        행정원장
                      </Text>
                    </FlexBox>
                  </SlideText>
                </SlideConts>
              </SwiperSlide>
              <SwiperSlide>
                <SlideConts>
                  <SlideImg>
                    <img src={img_doctor3} alt="의사 이미지" />
                  </SlideImg>
                  <SlideText>
                    <Text
                      color={ColorLightBlack}
                      className="ellipsis text-xs md:text-base"
                    >
                      병원의 특수성으로 인한 공급 프로세스가 타 병원과 상이한 부분이 많아, 적합한 병원 전용 구매시스템을 찾기가 어려웠습니다.
Canon ERP의 경우 구매,재고관리 큰 기틀을 표준화하고 하부 기능들을 매우 세분화 하여 여러 프로세스들을 적합하게 적용할 수 있다는 장점이 있습니다.

                    </Text>
                    <FlexBox
                      align={"flex-end"}
                      direction={"column"}
                      className="mt-7 md:mt-14"
                    >
                      <Text
                        color={ColorBlack}
                        weight={"600"}
                        className="text-xs md:text-sm"
                      >
                        00병원
                      </Text>
                      <Text
                        color={ColorBlack}
                        weight={"700"}
                        className="text-sm md:text-base"
                      >
                        총무과장
                      </Text>
                    </FlexBox>
                  </SlideText>
                </SlideConts>
              </SwiperSlide>
              <SwiperSlide>
                <SlideConts>
                  <SlideImg>
                    <img src={img_doctor4} alt="의사 이미지" />
                  </SlideImg>
                  <SlideText>
                    <Text
                      color={ColorLightBlack}
                      className="ellipsis text-xs md:text-base"
                    >
                      현재 병원에 이직하기전 사용하던 시스템이 Canon ERP 였었습니다.
새로운 환경에서 ERP 도입의 필요성을 느끼고 타사 병원 전용 ERP와 비교하였으나, 조회 속도와 기능 면에서 Canon ERP 선택하게 되었습니다.

                    </Text>
                    <FlexBox
                      align={"flex-end"}
                      direction={"column"}
                      className="mt-7 md:mt-14"
                    >
                      <Text
                        color={ColorBlack}
                        weight={"600"}
                        className="text-xs md:text-sm"
                      >
                        00병원
                      </Text>
                      <Text
                        color={ColorBlack}
                        weight={"700"}
                        className="text-sm md:text-base"
                      >
                        총무팀장
                      </Text>
                    </FlexBox>
                  </SlideText>
                </SlideConts>
              </SwiperSlide>
              
              
            </Swiper>
          </Container>
        </SlideContainer>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 120px 0;
  overflow: hidden;

  @media all and (max-width: 767px) {
    padding: 50px 0;
  }
`;
const SlideContainer = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  overflow: hidden;
`;
const SlideConts = styled.div`
  border: 1px solid ${ColorLineGray};
  border-radius: 6px;
  overflow: hidden;
`;
const SlideImg = styled.div`
  position: relative;
  padding-bottom: 78%;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;
const SlideText = styled.div`
  padding: 20px;

  .ellipsis {
    white-space: inherit;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.4rem;
    height: 12.5rem;
    word-break: keep-all;
  }

  @media all and (max-width: 767px) {
    padding: 12px;

    .ellipsis {
      line-height: 1.2rem;
      height: 2.4rem;
    }
  }
`;

export default MainAsk;
