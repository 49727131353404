import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Th, Td, Table, Text, Btn, FlexBox } from "../../../Assets/css/common";
import {
  ColorBlack,
  ColorLightGray,
  ColorMainBlue,
} from "../../../Utiles/Color";
import { API } from "../../../Utiles/API";
import Pagination from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";

// Cannon ERP > 소식 > 가입현황

function NewsJoin() {
  const limit = 10;
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);

  //========== API
  const getJoinList = async () => {
    const res = await API.get("/regusers");
    const resData = res.data.regusers;
    const filterData = resData.map((e, i) => {
      const idx = resData.length - i;
      return { ...e, idx: idx };
    });
    setTotalData(filterData);
    setData(showListPc(filterData, page, limit));
    setLoading(false);
  };
  const showListPc = (data, page, limit) => {
    const idxLast = page * limit;
    const idxFirst = idxLast - limit;
    const showItems = data.slice(idxFirst, idxLast);
    return showItems;
  };
  const showListMobile = (data, page, limit) => {
    const idxLast = page * limit;
    const showItems = data.slice(0, idxLast);
    return showItems;
  };
  const moreBtnClick = () => {
    setPage((state) => (state += 1));
  };

  useEffect(() => {
    getJoinList();
  }, []);
  useEffect(() => {
    const totalPage = Math.ceil(totalData?.length / limit);
    if (totalPage <= page) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [data]);
  useEffect(() => {
    const winW = window.innerWidth;

    setData();
    if (winW > 767) {
      setData(showListPc(totalData, page, limit));
    } else {
      setData(showListMobile(totalData, page, limit));
    }
  }, [page]);

  return (
    <>
      <Table className="block md:table">
        <colgroup className="hidden md:table-column-group">
          <col style={{ width: "10.5%" }} />
          <col />
          <col style={{ width: "14%" }} />
        </colgroup>
        <thead className="hidden md:table-header-group">
          <tr>
            <Th size={"16px"} bgColor={ColorLightGray}>
              No
            </Th>
            <Th size={"16px"} bgColor={ColorLightGray}>
              병원명
            </Th>
            <Th size={"16px"} bgColor={ColorLightGray}>
              가입일
            </Th>
          </tr>
        </thead>
        <tbody className="block md:table-row-group">
          {loading ? (
            <tr className="block md:table-row">
              <TdLoad colSpan="3" className="block md:table-cell">
                <Loading />
              </TdLoad>
            </tr>
          ) : (
            data?.length === 0 && (
              <tr className="block md:table-row">
                <TdNone colSpan="3" className="block md:table-cell">
                  <Text className="text-xs md:text-sm">
                    가입 현황이 없습니다.
                  </Text>
                </TdNone>
              </tr>
            )
          )}
          {data?.map((e) => (
            <tr key={e.idx} className="block md:table-row">
              {/* pc */}
              <Td className="hidden md:table-cell">
                <Text size={"14px"}>{e.idx}</Text>
              </Td>
              <Td align={"left"} className="hidden md:table-cell">
                <Text size={"14px"} color={ColorBlack}>
                  {e.corpname}
                </Text>
              </Td>
              <Td className="hidden md:table-cell">
                <Text size={"14px"}>{e.bdate}</Text>
              </Td>
              {/* mobile */}
              <Tdm className="block md:hidden">
                <Btn>
                  <FlexBox
                    justify={"space-between"}
                    gap={"12px"}
                    className="mb-1"
                  >
                    <Text size={"12px"} color={ColorMainBlue}>
                      {e.corpname}
                    </Text>
                    <Text size={"12px"} color={ColorBlack}>
                      {e.bdate}
                    </Text>
                  </FlexBox>
                </Btn>
              </Tdm>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="mt-7 hidden md:block">
        <Pagination
          limit={limit}
          total={totalData.length}
          page={page}
          setPage={setPage}
        ></Pagination>
      </div>
      {showMore ? (
        <Btn
          size={"12px"}
          color={ColorMainBlue}
          weight={"600"}
          border={`1px solid ${ColorMainBlue}`}
          radius={"24px"}
          width={"120px"}
          height={"40px"}
          className="block md:hidden mt-7.5 mx-auto"
          onClick={moreBtnClick}
        >
          더보기
        </Btn>
      ) : (
        ""
      )}
    </>
  );
}

const TdLoad = styled(Td)`
  height: 490px;
  vertical-align: top;

  @media all and (max-width: 767px) {
    height: auto;
    padding: 22px 12px;
  }
`;
const TdNone = styled(Td)`
  @media all and (max-width: 767px) {
    padding: 22px 12px;
  }
`;
const Tdm = styled(Td)`
  padding: 0;

  button {
    width: 100%;
    padding: 14px 12px;
    text-align: left;

    i {
      display: block;
    }
  }
`;

export default NewsJoin;
