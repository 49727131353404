import styled from "styled-components";
import {
  ColorMainBlue,
  ColorWhite,
  ColorLightGray,
  ColorGray,
  ColorLightBlack,
  ColorBlack,
  ColorDarkBlack,
} from "../../Utiles/Color";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;

  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
`;
export const Container700 = styled(Container)`
  max-width: 700px;
`;
export const Container412 = styled(Container)`
  max-width: 412px;
  padding: 0 16px;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};
  flex-wrap: ${(props) => props.wrap ?? props.wrap};
  flex-direction: ${(props) => props.direction ?? props.direction};
  flex: ${(props) => props.flex ?? props.flex};
  gap: ${(props) => props.gap ?? props.gap};
  width: ${(props) => props.width ?? props.width};
`;

export const Text = styled.p`
  font-size: ${(props) => props.size ?? props.size};
  font-weight: ${(props) => props.weight ?? props.weight};
  color: ${(props) => props.color || ColorLightBlack};
  text-align: ${(props) => props.align ?? props.align};

  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const Btn = styled.button`
  font-size: ${(props) => props.size ?? props.size};
  font-weight: ${(props) => props.weight ?? props.weight};
  color: ${(props) => props.color || ColorLightBlack};
  text-align: ${(props) => props.align ?? props.align};
  width: ${(props) => props.width ?? props.width};
  height: ${(props) => props.height ?? props.height};
  padding: ${(props) => props.padding ?? props.padding};
  border: ${(props) => props.border ?? props.border};
  border-radius: ${(props) => props.radius ?? props.radius};
  background: ${(props) => props.bgColor ?? props.bgColor};
`;

export const CheckIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${ColorGray};
  background-color: ${ColorWhite};

  svg {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    border-radius: 50%;
  }
`;
export const CheckLabel = styled.label`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + ${CheckIcon} {
      border-color: ${ColorMainBlue};
      background-color: ${ColorMainBlue};
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  @media all and (max-width: 767px) {
    border-top: 1px solid ${ColorLightGray};
  }
`;
export const Th = styled.th`
  font-size: ${(props) => props.size ?? props.size};
  font-weight: ${(props) => props.weight ?? props.weight};
  color: ${(props) => props.color || ColorDarkBlack};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "12px 10px"};
  background-color: ${(props) => props.bgColor || ""};
`;
export const Td = styled.td`
  font-size: ${(props) => props.size ?? props.size};
  font-weight: ${(props) => props.weight ?? props.weight};
  color: ${(props) => props.color || ColorBlack};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "12px 10px"};
  background-color: ${(props) => props.bgColor || ""};
  border-bottom: 1px solid ${ColorLightGray};
`;

export const EditorWrap = styled.div`
  font-size: 13px;
  color: ${ColorLightBlack};

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }

  .ql-editor {
    height: auto;
    padding: 0;
  }
`;

export const LoadingFull = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;
