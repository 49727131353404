import React from "react";
import styled from "styled-components";
import { ColorDarkBlack, ColorMainBlue } from "../../../Utiles/Color";
import { Text } from "../../../Assets/css/common";
import img_intro5 from "../../../Assets/images/intro_img05.jpg";
import img_intro6 from "../../../Assets/images/intro_img06.jpg";
import img_intro7 from "../../../Assets/images/intro_img07.jpg";

function IntroStock() {
  return (
    <>
      <div>
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          <span style={{ color: ColorMainBlue }}>
            입고와 동시에 출고되는 기능으로
          </span>{" "}
          <br />
          중복작업 제거!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro5} alt="입고, 출고 작업 이미지" />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          신청서 기반으로 <br />
          출고를 바로바로 시행!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro6} alt="출고 시행 이미지" />
        </IntroImg>
      </div>
      <div className="mt-12.5 md:mt-37.5">
        <Text
          color={ColorDarkBlack}
          weight={"700"}
          align={"center"}
          className="text-lg md:text-3xl lg:text-5xl break-keep"
        >
          산정불가 품목은 <br />
          재고실사를 통하여 편리하게!
        </Text>
        <IntroImg className="mt-5 md:mt-12.5">
          <img src={img_intro7} alt="재고실사 이미지" />
        </IntroImg>
      </div>
    </>
  );
}

const IntroImg = styled.div`
  img {
    margin: 0 auto;
  }
`;

export default IntroStock;
