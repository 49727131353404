import React from "react";
import styled from "styled-components";
import {
  ColorDarkGray,
  ColorLightBlack,
  ColorMainBlue,
  ColorWhite,
} from "../../Utiles/Color";
import { Btn, FlexBox, Text } from "../../Assets/css/common";

function ConfirmModal(props) {
  const { text, onCancel, onSubmit } = props;

  return (
    <>
      <ModalBg>
        <ModalWrap>
          <Text
            size={"16px"}
            color={ColorLightBlack}
            weight={"700"}
            align={"center"}
          >
            {text}
          </Text>
          <FlexBox
            align={"center"}
            justify={"center"}
            gap={"10px"}
            className="mt-8.5"
          >
            {onCancel && (
              <Btn
                size={"14px"}
                color={ColorDarkGray}
                width={"80px"}
                height={"32px"}
                border={`1px solid ${ColorDarkGray}`}
                radius={"4px"}
                bgColor={ColorWhite}
                onClick={onCancel}
              >
                취 소
              </Btn>
            )}
            <Btn
              size={"14px"}
              color={ColorWhite}
              width={"80px"}
              height={"32px"}
              radius={"4px"}
              bgColor={ColorMainBlue}
              onClick={onSubmit}
            >
              확 인
            </Btn>
          </FlexBox>
        </ModalWrap>
      </ModalBg>
    </>
  );
}

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const ModalWrap = styled.div`
  position: relative;
  display: block;
  width: 96%;
  max-width: 320px;
  padding: 50px 20px 40px;
  border-radius: 10px;
  background-color: ${ColorWhite};

  @media all and (max-width: 767px) {
    max-width: 260px;
  }
`;

export default ConfirmModal;
